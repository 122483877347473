
import { Resource } from '@/infrastructure/resource/resource'
import { reactive } from 'vue'
import { DynamicGrid, DynamicGridRequest, ImageType } from '@/components/Shared/dynamicList/DynamicListTypes'
import { Out } from '@/infrastructure/frontent/clientMessage'
import { DynamicRecord } from '../Dynamicform/DynamicFormData'
import { getDynamicGridFromApi, getFishBookEntriesWithDetail } from "@/infrastructure/serviceworker-related/DynamicGridServiceWorker"
import { resolveMainListResourceTitel } from './DynamicListBusinessCase'
import { reSyncFishbookApi } from '@/infrastructure/serviceworker-related/ReSyncFishBook'
import { getWildBookEntriesWithDetail } from '@/infrastructure/serviceworker-related/DynamicGridHuntingService'
import { reSyncWildBookApi } from '@/infrastructure/serviceworker-related/ReSyncWildBook'
import { TierartRules } from '@/infrastructure/serviceworker-related/TierartZusatzService'
import { overWriteValueFromDateValue, overwriteSerializedLocalDate } from '@/infrastructure/functional/datetimehelper'



    export default function useDynamicListBase( request: DynamicGridRequest ) {

        let gridObject : DynamicGrid | undefined = {userId: request.userId , viewId: request.viewId}
        const grid = reactive({ result: gridObject });

        async function getDynamicGridData( request: DynamicGridRequest , filter: Array<DynamicRecord> | undefined ) : Promise<void> {   
            const clonedRequest = JSON.parse( JSON.stringify(request) )
            try {
                if ( clonedRequest && clonedRequest.revierNr && typeof(clonedRequest.revierNr) === "object" ) {
                    clonedRequest.revierNr = clonedRequest.revierNr.key                     
                }
                if ( filter !== undefined ) {
                    clonedRequest.dynamicFilterItems = JSON.parse( JSON.stringify(filter) )                    
                    overwriteSerializedLocalDate(filter, clonedRequest.dynamicFilterItems)
                    for ( const row of clonedRequest.dynamicFilterItems) {
                        try {
                            if ( row.dateValue && row.dateValue !== undefined) {
                                row.value =overWriteValueFromDateValue(row.dateValue)
                            }
                        } catch (e) {
                            Out.noOperation(e)
                        }
                    }
                }
            } catch(e) {
                Out.noOperation()
            }

            if ( request.viewId === "fishBookEntriesWithDetail" ) {
                 await reSyncFishbookApi()
                 const tempResult = await getFishBookEntriesWithDetail(request.viewId, false,clonedRequest)
                 gridObject = tempResult?.getValue()
            } 
            
            else if (request.viewId === "wildBookEntriesWithDetail") {
                await reSyncWildBookApi()
                const tempResult = await getWildBookEntriesWithDetail(request.viewId, false,clonedRequest)
                gridObject = tempResult?.getValue()                               
                await TierartRules.loadRuleData()
                if ( gridObject && gridObject.rows) {
                    for( const row of gridObject?.rows) {
                        if ( row.cell && row.cell.readableIdentification && row.detailForm && row.detailForm.view) {
                            await TierartRules.handleZusatzInfo(row.detailForm.viewId, row.detailForm?.view)
                            await TierartRules.handlePflichtfelder(row.detailForm.viewId, row.detailForm?.view)
                            await TierartRules.handleZusatzInfoData(row)
                        }
                    }
                }

            } 
            else {
                gridObject = await getDynamicGridFromApi(clonedRequest, false)
            }
             

            if ( gridObject === undefined) return

            if ( gridObject.rows) {
                for (const row of gridObject.rows ) {   

                    const resourceTitle = await resolveMainListResourceTitel(request.viewId, row?.cell?.content?.titleResourceId ?? "", row )                                
                    if ( resourceTitle !== null &&  resourceTitle !== "")
                        row.cell.content.title = resourceTitle
                    
                    const resourceContent = await Resource.getResourceText(row?.cell?.content?.contentResourceId ?? "")
                    if ( resourceContent !== null &&  resourceContent !== "")
                        row.cell.content.content = resourceContent

                    if ( row?.cell?.additional !== null && row?.cell?.additional !== undefined ) {
                        const resourceText = await Resource.getResourceText(row?.cell?.additional?.textResourceId ?? "")
                        if ( resourceText !== null &&  resourceText !== "") {
                            row.cell.additional.text = resourceText
                        }
                    }

                    if ( row.cell.image && row?.cell?.image.imageType) {
                        row.cell.image.imagePath =  getGridIcon(row?.cell?.image?.imageState, row?.cell?.image?.imageType) ?? ""                       
                    }

                    if (row.rows) {
                        for (const detailRow of row.rows ) {                       
                            const resourceTitle = await Resource.getResourceText(detailRow?.cell?.content?.titleResourceId ?? "")                  
                            if ( resourceTitle !== null &&  resourceTitle !== "")
                                detailRow.cell.content.title = resourceTitle
                            
                            const resourceContent = await Resource.getResourceText(detailRow?.cell?.content?.contentResourceId ?? "")
                            if ( resourceContent !== null &&  resourceContent !== "")
                                detailRow.cell.content.content = resourceContent

                            if ( detailRow?.cell?.additional !== null && detailRow?.cell?.additional !== undefined ) {
                                const resourceText = await Resource.getResourceText(detailRow?.cell?.additional?.textResourceId ?? "")
                                if ( resourceText !== null &&  resourceText !== "") {
                                    detailRow.cell.additional.text = resourceText
                                }
                            }

                            if ( detailRow.cell.image && detailRow?.cell?.image.imageType) {
                                detailRow.cell.image.imagePath =  getGridIcon(detailRow?.cell?.image?.imageState, detailRow?.cell?.image?.imageType) ?? ""                       
                            }                            
                        }
                    }
                }    
            }
            grid.result = gridObject
        }

        const getGridIcon = (imageState: string | null | undefined, imageType: string) => {
            if ( imageState === null || imageState === undefined || imageState === "") {
                imageState = "Valid"
            }

            const pathFish = "img/icons/fischli";
            const pathHunt = "img/icons/hirsch";
            const pathSynced = "img/icons/synced.svg";
            const pathNotSynced = "img/icons/notsynced.svg";
            const pathFailedSynced = "img/icons/failedsync.svg";


            let currentPath = ""

            switch(imageType) {
            case "fish":        
                currentPath = pathFish
                break    
            case "hunt":
                currentPath = pathHunt
                break
            case "synced":
                currentPath = pathSynced
                break                
            case "notsynced":
                currentPath = pathNotSynced
                break                                
            case "failedsync":
                currentPath = pathFailedSynced
                break                                
            }


            if ( currentPath && currentPath.indexOf(".svg") >= 0) {
                return currentPath
            }


            if (imageState.toLowerCase().trim() === "valid") {
                return currentPath + "-g.svg";
            }
            if (imageState.toLowerCase().trim() === "future") {
                return currentPath + "-o.svg";
            }
            if (imageState.toLowerCase().trim() === "past") {
                return currentPath + "-r.svg";
            }
            if (imageState.toLowerCase().trim() === "invalid") {
                return currentPath + "-r.svg";
            }
            return currentPath + ".svg";  
        }

      
        return {
            getDynamicGridData,
            grid
        }
    }
