
  import { onMounted, onUnmounted, reactive, ref} from "vue";
  import { useStore } from "vuex";
  import { UserProfile } from "@/infrastructure/backend/userProfile";
  import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
  import { NavigationStateElement, NavigationStateElements, StateNavigationAction } from "@/business/application/statemachine/StateInterfaces";
  import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
  import { Commands } from "@/business/application/commands/Command";
  import { StateWebShop } from '@/business/application/statemachine/concrete/StateWebShop';
  import { StateProduct } from '@/business/application/statemachine/concrete/StateProduct';
  import { DynamicNavigation } from "@/components/Shared/dynamicNavigation/DynamicNavigation";
  import { Out } from "@/infrastructure/frontent/clientMessage";
  import { LocalWarenkorb, ShoppingCardLocal } from "./LocalWarenkorb";
  import { RemoteZahlung } from "./RemoteZahlung";
  import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
  import { AppState } from "@/AppState";
  import { RemoteWarenKorb } from "./RemoteWarenKorb";
  import { Resource } from "@/infrastructure/resource/resource";
  import { BusinessZahlungAusgefuehrt } from "./BusinessZahlungAusgefuehrt";

  
  export default {
    name: "WebshopFailed",
    inheritAttrs: false,
    components: {},
  
    setup() {
      const store = useStore();
      DynamicNavigation.reset()
      let unsubscribe:any = {};
  
      

      let card: ShoppingCardLocal = {
        artificalId: "",
        id: "",
        language: "",
        autoConfirmationEnabled: false,
        currency: "",
        customer: {
        userId: ""
        },
        shoppingCardLineItems: undefined,
        postFinanceScriptReference: "",
        transactionId: "",
        transactionReference: "",
        amountIncludeTax: "",
        brand: "",
        providerStatus: "",
        transferDatum: "",
        errorResourceId: ""
      }

      const cardResult = reactive({
        shoppingCard: card
      })
      
      const request = reactive({
      title: "",
      betrag: "",
      offline: "",
      ueberpruefen: ""
      })

      const offlineKasse = ref<boolean>(false)

      const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
     

      const stateMachine = StateMachineServiceLocator.get();
      stateMachine.setAppUpdateStateDetailed( new StateWebShop(), {
        oldMainState: "WebShop", 
          oldState: "WebShopWarenKorb", 
          newMainState: "WebShop", 
          newState: "WebShopKasseFailed", 
          routeName: "WebShopKasse",   
        })

      let currentState = stateMachine.getCurrentResultState();
      stateMachine.next( { navigation: StateNavigationAction.Next, conditions: []}, { lookahead: false,});
      changeNavigation(store);

      const zahlungsStatus: string  =  BusinessZahlungAusgefuehrt.ZahlungsStatus.State
      

      const backGroundZahlung = reactive({ 
        state: zahlungsStatus,
        verzoegert: BusinessZahlungAusgefuehrt.ZahlungsStatus.Verzoegert,
        verzoegertTitle: ""
      })

      BusinessZahlungAusgefuehrt.Reset()
      BusinessZahlungAusgefuehrt.HideWebShopNavbar()
  
      async function navigate(nav: any) {
        var userProfile = new UserProfile()
        const userData = await userProfile.GetProfileData()
        if ( !userData.online) {
          const result = await AppState.getAppState()
          if ( result.WebShopStart === "Fishing") {
            stateMachine.setAppUpdateStateDetailed( new StateProduct(), {
            oldMainState: "Fishing", 
            oldState: "Fishing", 
            newMainState: "Product", 
            newState: "Products", 
            routeName: "Products",   
            })

            stateMachine.next( { navigation: StateNavigationAction.Next, conditions: [{nameId:"Fishing", value:"Fishing"}]}, { lookahead: false,});
            changeNavigation(store);
            return
          }
         
          if ( result.WebShopStart === "Hunting") {
            stateMachine.setAppUpdateStateDetailed( new StateProduct(), {
            oldMainState: "Hunting", 
            oldState: "Hunting", 
            newMainState: "Product", 
            newState: "Products", 
            routeName: "Products",   
            })

            stateMachine.next( { navigation: StateNavigationAction.Next, conditions: [{nameId:"Hunting", value:"Hunting"}]}, { lookahead: false,});
            changeNavigation(store);
            return
          }
         
        }

        stateMachine.setAppUpdateStateDetailed( new StateWebShop(), {
          oldMainState: "WebShop", 
          oldState: "WebShopKasse", 
          newMainState: "WebShop", 
          newState: "WebShopWarenKorb", 
          routeName: "WebShopWarenKorb",   
        })

        stateMachine.previous( { navigation: StateNavigationAction.Next, conditions: []}, { lookahead: false,});
        changeNavigation(store);


       
      }
  
      async function load() {
        loaderState.changeLoaderState({isInProgress: true})
        backGroundZahlung.verzoegert = await Resource.getResourceText(`WebShop.Abbruch.Content`)
        backGroundZahlung.verzoegertTitle = await Resource.getResourceText(`WebShop.Abbruch.Title`)
        var userProfile = new UserProfile()
        const userData = await userProfile.GetProfileData()
        if ( !userData.online) { 
          offlineKasse.value = true
        } else {
          offlineKasse.value = false
        }


        request.title = await Resource.getResourceText("WebShop.Failed.Title")
        request.betrag = await Resource.getResourceText("WebShop.Failed.Betrag")
        request.offline = await Resource.getResourceText("WebShop.Failed.Offline")
        request.ueberpruefen = await Resource.getResourceText("WebShop.Failed.Ueberpruefen")

        await AppState.getAppState()

        if( backGroundZahlung.state === "STOPPED") {
          //await RemoteWarenKorb.cleanUpWebShopProducts()
          await LocalWarenkorb.Clear()
          loaderState.changeLoaderState({isInProgress: false})
          return
        }

        let card = await LocalWarenkorb.GetShoppingCard()
        if (card && card.id && card.id.length > 0) {
          cardResult.shoppingCard = card
           card = await RemoteZahlung.cancelZahlung()
           if ( card ) {
            cardResult.shoppingCard = card
           }
           //await RemoteWarenKorb.cleanUpWebShopProducts()
           await LocalWarenkorb.CreateShoppingCard()
           loaderState.changeLoaderState({isInProgress: false})
        } else {
          //await RemoteWarenKorb.cleanUpWebShopProducts()
          await LocalWarenkorb.CreateShoppingCard()
          loaderState.changeLoaderState({isInProgress: false})
        }
        
       
      }

      load()


      function deletePaymentWrapper() {
        const element = document.getElementById("paymentFrameWrapper")
        if (element) element.remove()
      }

      onMounted(() => {    
        deletePaymentWrapper()  
        unsubscribe = store.subscribe((mutation) => {
          if ( !DynamicNavigation.isNavigationEnabled()) {
            if (currentState && currentState.navigation) {
              try {
                DynamicNavigation.saveLastStaticNavigationState([currentState.navigation?.nav1,currentState.navigation?.nav2,currentState.navigation?.nav3,currentState.navigation?.nav4])
              } catch(e) {
                Out.noOperation("")
              }
            }
          }
  
          if (!DynamicNavigation.isAssigned("WebShop")) return       
  
          if (mutation.type === "navigationState/clickNavigationItem1") {
            navigate(currentState.navigation?.nav1);
          }
          if (mutation.type === "navigationState/clickNavigationItem2") {
            navigate(currentState.navigation?.nav2);
          }
          if (mutation.type === "navigationState/clickNavigationItem3") {
            navigate(currentState.navigation?.nav3);
          }
          if (mutation.type === "navigationState/clickNavigationItem4") {
            navigate(currentState.navigation?.nav4);
          }
        });
  
        
      });
  
      onUnmounted(() => {
        try {
          unsubscribe();
        } catch(e) {
          Out.noOperation("")
        }
      });
  
      return {
        cardResult,
        request,
        offlineKasse,
        backGroundZahlung
      };
    },
  };
  