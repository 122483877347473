import { openDB, IDBPDatabase, deleteDB, wrap, unwrap } from 'idb';
import { Dictionary } from '../generics/dictionary';
import { IUserProfile } from '../backend/userProfile';
import { Identity } from '../backend/userIdentity';
import store from '@/store';
import { Out } from '../frontent/clientMessage';

const version = "v1";
const localDbName = "efj-Store";
export const personProfileTable = "person-profile" + "-" + version;
export const localizationTable = "localization-resources" + "-" + version;
export const personProductsTable = "person-products" + "-" + version;
export const fishbookTable = "fishbook-master-detail" + "-" + version
export const offlineFormsTable = "forms-offline" + "-" + version
export const PatentTable = "fischerei-patent" + "-" + version
export const FischereiRevierTable = "fischerei-revier" + "-" + version
export const fischFangStartStopTable = "fischfang-start-stop" + "-" + version
export const FischereiRevierPerson = "FischereiRevierPerson" + "-" + version
export const Sprache = "Sprache" + "-" + version
export const Anrede = "Anrede" + "-" + version
export const Fischart = "Fischart" + "-" + version
export const SystemParameter = "SystemParameter" + "-" + version


export const wildbookTable = "wildbook" + "-" + version
export const WildbuchTodesursache = "WildbuchTodesursache" + "-" + version
export const WildbuchTyp = "WildbuchTyp" + "-" + version
export const WildbuchTierGeschlecht = "WildbuchTierGeschlecht" + "-" + version
export const WildbuchTieralter = "WildbuchTieralter" + "-" + version
export const WildbuchJagdBetriebsart = "WildbuchJagdBetriebsart" + "-" + version
export const WildbuchNachsuche = "WildbuchNachsuche" + "-" + version
export const WildbuchBeobachtungSpezialFall = "WildbuchBeobachtungSpezialFall" + "-" + version
export const WildbuchBeobachtungMethode = "WildbuchBeobachtungMethode" + "-" + version
export const WildbuchErfassungStatus = "WildbuchErfassungStatus" + "-" + version
export const WildbuchZeit = "WildbuchZeit" + "-" + version
export const WildbuchOrtsbeschreibung = "WildbuchOrtsbeschreibung" + "-" + version
export const Tierart = "Tierart" + "-" + version
export const JagdRevier = "JagdRevier" + "-" + version
export const TierartZusatzRule = "TierartZusatzRule" + "-" + version
export const TierartZusatzRecord = "TierartZusatzRecord" + "-" + version
export const Vermarktung = "Vermarktung" + "-" + version
export const Nachtsichthilfe = "Nachtsichthilfe" + "-" + version
export const Nachtsichtzielhilfe = "Nachtsichtzielhilfe" + "-" + version

export const WarenKorb = "WarenKorb" + "-" + version
export const WebShopState = "WebShopState" + "-" + version
export const AppStateTable = "AppState" + "-" + version
export const ClientSettingsTable = "ClientSettingsTable" + "-" + version
export const Land = "Land" + "-" + version
export const LastSyncedObjects = "LastSyncedObjects" + "-" + version
export const WildSchutzGebiet = "WildSchutzGebiet" + "-" + version
export const WildRaum = "WildRaum" + "-" + version
export const Gemeinde = "Gemeinde" + "-" + version




const stores: Array<string> = []
stores.push(personProfileTable)
stores.push(localizationTable)
stores.push(personProductsTable)
stores.push(offlineFormsTable)
stores.push(PatentTable)
stores.push(FischereiRevierTable)
stores.push(fishbookTable)
stores.push(fischFangStartStopTable)
stores.push(FischereiRevierPerson)
stores.push(Sprache)
stores.push(Anrede)
stores.push(Fischart)
stores.push(SystemParameter)

stores.push(wildbookTable)
stores.push(WildbuchTodesursache)
stores.push(WildbuchTyp)
stores.push(WildbuchTierGeschlecht)
stores.push(WildbuchTieralter)

stores.push(WildbuchJagdBetriebsart)
stores.push(WildbuchNachsuche)
stores.push(WildbuchBeobachtungSpezialFall)
stores.push(WildbuchBeobachtungMethode)
stores.push(WildbuchErfassungStatus)
stores.push(WildbuchZeit)
stores.push(WildbuchOrtsbeschreibung)
stores.push(Tierart)
stores.push(JagdRevier)
stores.push(TierartZusatzRule)
stores.push(TierartZusatzRecord)
stores.push(Vermarktung)
stores.push(Nachtsichthilfe)
stores.push(Nachtsichtzielhilfe)
stores.push(WarenKorb)
stores.push(WebShopState)
stores.push(AppStateTable)
stores.push(ClientSettingsTable)
stores.push(Land)
stores.push(LastSyncedObjects)
stores.push(WildSchutzGebiet)
stores.push(WildRaum)
stores.push(Gemeinde)




const userStores: Array<string> = []
//userStores.push(personProfileTable)
userStores.push(personProductsTable)
userStores.push(FischereiRevierTable)
userStores.push(PatentTable)
userStores.push(fishbookTable)
userStores.push(fischFangStartStopTable)
userStores.push(FischereiRevierPerson)

userStores.push(wildbookTable)
userStores.push(WildbuchTodesursache)
userStores.push(WildbuchTyp)
userStores.push(WildbuchTierGeschlecht)
userStores.push(WildbuchTieralter)
userStores.push(WildbuchJagdBetriebsart)
userStores.push(WildbuchNachsuche)
userStores.push(WildbuchBeobachtungSpezialFall)
userStores.push(WildbuchBeobachtungMethode)
userStores.push(WildbuchErfassungStatus)
userStores.push(WildbuchZeit)
userStores.push(WildbuchOrtsbeschreibung)
userStores.push(Tierart)
userStores.push(JagdRevier)
userStores.push(TierartZusatzRule)
userStores.push(TierartZusatzRecord)
userStores.push(Vermarktung)
userStores.push(Nachtsichthilfe)
userStores.push(Nachtsichtzielhilfe)
userStores.push(WarenKorb)
userStores.push(WebShopState)
userStores.push(AppStateTable)
userStores.push(WildSchutzGebiet)
userStores.push(WildRaum)
userStores.push(Gemeinde)


export function storeExists(storeName: string) {
  if ( stores && stores.length > 0) {
    if (stores.indexOf(storeName) >-1 ) return true 
  }
  return false
}

export async function openDatabase() : Promise<IDBPDatabase<unknown>> {
  const dbPromise = await openDB(localDbName, 5, {
    upgrade(db) {
      stores.forEach(store => {
        if (!db.objectStoreNames.contains(store)) {
          db.createObjectStore(store, {
            keyPath: "id"                        
          });
        }
      });
    }
  });
  return dbPromise;
}


export async function addOrReplaceRecord<T>(storeName: string, T: Dictionary<T>) :Promise<void> {
  const db = await openDatabase();
  try {
    if ( ! T || !T.id) return
    if ( ! storeName) return
    if ( ! storeExists(storeName)) return

    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    const exists = await store.get(T.id as unknown as string);
    if ( exists !== undefined) {
      store.delete(T.id as unknown as string);
    }  
    await store.put(T);
    await tx.done;
    db.close()
  } catch(e) {
    console.error(`Error on addOrReplaceRecord - storename: ${storeName} - ${e}`)
  }
 
}

export async function deleteDatabase() {
  for( const store of stores) {
    await deleteStore(store)
  }
}

export async function deleteUnsyncedDatabase() {
  for( const store of stores) {
     if( store === fishbookTable || store === wildbookTable) continue
     await deleteStore(store)
  }
}

export async function deleteUserContent() {
  try {
    for( const store of userStores) {     
      await deleteStore(store)
    }
  } catch(e) { 
    Out.noOperation(e)
  }
}

// export async function deleteDatabase() {
//   for( const store of stores) {
//      const records =  await  loadAllRecords(store)
//      for( const record of records as any) {
//       await deleteRecord(store, record.id)
//      }
//   }
// }

// export async function deleteUnsyncedDatabase() {
//   for( const store of stores) {
//      if( store === fishbookTable || store === wildbookTable) continue
//      const records =  await  loadAllRecords(store)
//      for( const record of records as any) {
//       await deleteRecord(store, record.id)
//      }
//   }
// }

// export async function deleteUserContent() {
//   for( const store of userStores) {     
//      const records =  await  loadAllRecords(store)
//      for( const record of records as any) {
//       await deleteRecord(store, record.id)
//      }
//   }

// }

// export async function deleteStore(storeName: string) {

//   const records =  await  loadAllRecords(storeName)
//   for( const record of records as any) {
//     await deleteRecord(storeName, record.id)
//    }
// }

export async function deleteStore(storeName: string) {
  const db = await openDatabase();
  if ( ! storeName) return
  if ( ! storeExists(storeName)) return
  const tx =  db.transaction(storeName, "readwrite");
  await db.clear(storeName)
  await tx.done;
  db.close()

}
  
export async function createStore(storeName: string) {
  const db = await openDatabase();
  if ( ! storeName) return
  if ( storeExists(storeName)) return
  const tx =  db.transaction(storeName, "readwrite");
  db.createObjectStore(storeName)
  await tx.done;
  db.close()

}




export async function deleteRecord(storeName: string, key: string) :Promise<void> {
  const db = await openDatabase();
  try {
    
    if ( ! storeName) return
    if ( ! storeExists(storeName)) return
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    const exists = await store.get(key);
    if ( exists !== undefined) {
      store.delete(key);
    }      
    await tx.done;
    db.close()
  } catch(e) {
    console.error(`Error on addOrReplaceRecord - storename: ${storeName} - ${e}`)
  }
 
}





export async function loadAllRecords(storeName: string) : Promise<Array<object>> {
  try {
    if ( ! storeExists(storeName)) return []
    const db = await openDatabase();
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    const data = await store.getAll();
    await tx.done;
    const result: object[] = []

    data.forEach(item => {
      result.push(item)
    });
    db.close()
    return result
    } catch(e) {
      console.error(`Error on loadAllRecords - storename: ${storeName} - ${e}`)
      return []
  }
}

export async function getRecord(storeName: string, id: string) : Promise<any> {
  try {
    if ( ! storeExists(storeName)) return
    const db = await openDatabase();
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    const data = await store.get(id);  
    await tx.done;
    db.close()
    return data; 
  } catch (e) {
    console.error(`Error on getRecord - storename: ${storeName} - ${e}`)
    return
  }
}

export async function recordCount(storeName: string) {
  const db = await openDatabase();
  try {
    if ( ! storeExists(storeName)) return 0
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    const data = await store.count()
    await tx.done;
    db.close()
    return data;
  }
  catch(e) {
    console.error(`Error on recordCount - storename: ${storeName} - ${e}`)
    return 0
  }
}