import { AsyncCommand, Command } from "../Command";
import { DynamicDataSet, DynamicFormData } from "@/components/Shared/Dynamicform/DynamicFormData";
import { FishingEdit } from "@/components/fishing/business/FishingEdit";
import { Resource } from "@/infrastructure/resource/resource";

export class RuleLoadFishing extends AsyncCommand {
  
 
  private dataSet: DynamicDataSet;
  
  constructor(    
    dataSet: DynamicDataSet
  ) {
    super();   
    this.dataSet = dataSet;
  }

  public async asyncExecute(): Promise<any> {

    

    const fishingEditState = await FishingEdit.getEditFishingStateAsync()
    if ( fishingEditState.startDate === undefined)
        return true

        if ( fishingEditState.isStarted ) {

            let revierContent = "";
            let revierTitle = "";
            let revierResource = "";
    
            let patentContent = "";
            let patentTitle = "";
            let patentResource = "";
        
            for ( const row of this.dataSet.view) {
                if (row.formularFeldname === "Patent") {               
                    patentResource = row.infoResourceId ?? ""
                    patentTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`) ?? ""
                    patentContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`) ?? ""   
                }
                if (row.formularFeldname === "Fischereirevier") {               
                    revierResource = row.infoResourceId ?? ""
                    revierTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`) ?? ""
                    revierContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`) ?? ""   
                }
    
            }
    


            this.dataSet.view.forEach(row => {
                if (row.formularFeldname === "RuleFischfangEintrag") {
                    row.layoutKlasse = "btn action__button_primary btn-block action__button"
                    row.istEditierbar = true
                    row.istSichtbar = true
                }

                if (row.formularFeldname === "RuleStartFishing") {
                    row.istSichtbar = false
                }
                if (row.formularFeldname === "RuleStopFishing") {
                    row.istSichtbar = true
                }


                if (row.formularFeldname === "Auswahlpatent") {               
                    row.value = fishingEditState.patent?.value
                    row.istSichtbar = true

                    row.infoTextContent = patentContent
                    row.infoTextTitle = patentTitle
                    row.infoResourceId = patentResource
                }

                if (row.formularFeldname === "Patent") {               
                    row.istSichtbar = false
                }

                if (row.formularFeldname === "Auswahlrevier") {               
                    row.value = fishingEditState.fischereiRevier?.value
                    row.istSichtbar = true

                    row.infoTextContent = revierContent
                    row.infoTextTitle = revierTitle
                    row.infoResourceId = revierResource
    

                }

                if (row.formularFeldname === "Fischereirevier") {               
                    row.istSichtbar = false
                }


            })       
        } else {
            this.dataSet.view.forEach(row => {
                if (row.formularFeldname === "RuleFischfangEintrag") {
                    row.layoutKlasse = "btn action__button_primary btn-block action__button"
                    row.istEditierbar = true
                    row.istSichtbar = false
                }

                if (row.formularFeldname === "RuleStartFishing") {
                    row.istSichtbar = true
                }
                if (row.formularFeldname === "RuleStopFishing") {
                    row.istSichtbar = false
                }


                if (row.formularFeldname === "Auswahlpatent") {               
                    row.value = fishingEditState.patent?.value
                    row.istSichtbar = false
                }

                if (row.formularFeldname === "Patent") {               
                    row.istSichtbar = true
                }

                if (row.formularFeldname === "Auswahlrevier") {               
                    row.value = fishingEditState.fischereiRevier?.value
                    row.istSichtbar = false
                }

                if (row.formularFeldname === "Fischereirevier") {               
                    row.istSichtbar = true
                }


            })       
        }
    return true
  }
}

