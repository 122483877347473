
  import { onMounted, onUnmounted, ref, reactive, defineComponent, computed } from "vue";
  import { useStore } from "vuex";  
  import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
  import { StateNavigationAction } from "@/business/application/statemachine/StateInterfaces";
  import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
  import { Commands } from "@/business/application/commands/Command"; 
  import { Identity } from "@/infrastructure/backend/userIdentity";
  import useDynamicListBase from "./DynamicListBase.vue";
  import router from "@/router";
  import {CreateDetailState, DynamicListToDetailFormChannelForRoot} from "@/components/Shared/dynamicList/Hooks/DynamicListToDetailFormChannel"
  import { DynamicDetailToFormChannelForRoot, createIntitialFormDetailState } from "./Hooks/DynamicDetailFormToListChannel";  
  import { Resource } from "@/infrastructure/resource/resource";  
  import { CreateParentListState, DynamicDetailListParentListChannelForRoot} from "@/components/Shared/dynamicListDetailList/Hooks/DynamicDetailListParentListToDatailListChannel"
  import { DynamicDetailListChannelForRoot, createIntitialListDetailState } from "../dynamicListDetailList/Hooks/DynamicDetailListToParentListChannel";
  import { DynamicGrid, DynamicGridRequest, DynamicGridRow, SyncStateRequest, SyncStateResponse } from "@/components/Shared/dynamicList/DynamicListTypes"
  import { SupportedDetailLists} from "@/components/Shared/dynamicListDetailList/DynamicDetailListTypes"
  import { useRoute } from "vue-router";
  import { AttachDynamicFilterChannelForRoot } from '@/components/Shared/dynamicList/filter/hooks/AttachDynamicFilterChannelForRoot';
  import { ApplyDynamicFilterChannelForRoot } from '@/components/Shared/dynamicList/filter/hooks/ApplyDynamicFilterChannel';
  import { FilterSupportedLists } from "./filter/hooks/DynamicFilterTypes";
  import { DetailsSupported } from "./Hooks/DynamicDetailTypes";
  import {sanitize} from "@/infrastructure/functional/sanitize"
  import { LocalStore } from "@/infrastructure/local-api/LocalStore"
  import { DynamicFilterFormStateForRoot } from "../Dynamicform/hooks/FilterForm";
  import { RoutedButtonBarCleanupChannelForRoot } from "../routed-button-bar/RoutedButtonBarCleanupChannel";
  import { RoutedButtonBarResponseChannelForRoot, createIntitialButtonBarRoute } from "../routed-button-bar/RoutedButtonResponseChannel";
  import { DynamicTriggerSaveFormForRoot } from "../Dynamicform/hooks/TriggerSaveForm";
  import { DynamicSaveFormForRoot } from "../Dynamicform/hooks/SaveFormData";
  import { DynamicRecord } from "../Dynamicform/DynamicFormData";
  import { openDialog, showError } from "@/infrastructure/observables/ModalDialogNotification";
  import { UserProfile } from "@/infrastructure/backend/userProfile";
  import { syncFishbook} from "@/infrastructure/serviceworker-related/SyncFishbook"
  import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
  import { findLocalFishingDetailRecordByRowId, findLocalFishingParentRecord, findLocalFishingParentRecordById } from "@/components/fishing/business/FishingAdd";
  import { resolveMainListResourceTitel, resolveSubListResourceTitel } from "./DynamicListBusinessCase";
  import { PDFExport, savePDF } from "@progress/kendo-vue-pdf";
  import { getDynamicPdfFromApi } from "@/infrastructure/serviceworker-related/DynamicGridServiceWorker";
  import { Out } from "@/infrastructure/frontent/clientMessage";
  import { PdfReport, Report } from "@/components/Shared/dynamicList/DynamicPdfTypes"
  import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
  import { syncWildbook } from "@/infrastructure/serviceworker-related/SyncWildbook";
  import { DynamicNavigation } from "@/components/Shared/dynamicNavigation/DynamicNavigation";
import { DynamicLoadFormForRoot } from "../Dynamicform/hooks/LoadFormData";
import { NotifyDynamicList } from "../dynamicNavigation/Hooks/NotifyDynamicList";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
import { DynamicLoadFormData, DynamicLoadFromListData } from "../Dynamicform/DynamicLoadFromListData";
import { DynamicListStore } from "./DynamicListStore";
import { BusinessChanges } from "./Hooks/BusinessFormChanges";
import { JagdRevierTitel, assignFormValues, findLocalHuntingRecord, findLocalHuntingRecordOverFormId, saveHuntingRecord } from "@/infrastructure/serviceworker-related/LocalWildBookDataAccess"
import { GeoStoreData } from "../geo/hooks/GeoStoreData";
import { RefreshServerObjects } from "@/infrastructure/resource/RefreshServerObjects";
import { overWriteValueFromDateValue, overwriteSerializedLocalDate } from "@/infrastructure/functional/datetimehelper";


  export default defineComponent ({
    name: "DynamicList",
    inheritAttrs: false,
    components: {      
       pdfexport: PDFExport,  
       //DynamicForm
    },
    props: {
        ViewId: {
          type: String,
          required: true,
          default: ""
        },
        DetailId: {
          type: String,
          required: true,
          default: ""
        },
        NavigationState: {
          type: String,
          required: true,
          default: ""
        },
        TitleResourceId: {
          type: String,
          required: true,
          default: ""
        },
        Filter: {
          type: String,
          required: false,
          default: ""
        },
        PageSize: {
          type: Number,
          required: false,
          default: 5
        },
        RevierNr: {
          type: String,
          required: false,
          default: ""
        },
        DetailList: {
          type: Boolean,
          required: false,
          default: false
        },
        RouteName: {
          type: String,
          required: false,
          default: undefined
        },
        DetailRouteName: {
          type: String,
          required: false,
          default: undefined
        },
        Form: {
          type: String,
          required: false,
          default: ""
        },
        Page: {
          type: String,
          required: false,
          default: ""
        },
        FilterType: {
          type: String,
          required: false,
          default: ""
        },
        Synced: {
          type: Boolean,
          required: false,
          default: false
        }
    },
    setup(props) {
      
      const store = useStore();
      let unsubscribe: any = {};
      
      const route=useRoute();    
      const clientRouteName = computed(() =>route.name?.toString())


      const stateMachine = StateMachineServiceLocator.get();
      const currentState = stateMachine.getCurrentResultState();
      
      const toDetailFormNotfication = DynamicListToDetailFormChannelForRoot.getService();
      const onDetailFormNotfication = DynamicDetailToFormChannelForRoot.getService();
    
      const toDetailListNotfication = DynamicDetailListParentListChannelForRoot.getService();
      const onDetailListNotification = DynamicDetailListChannelForRoot.getService();

      const attachedTo = AttachDynamicFilterChannelForRoot.getService()
      let applyFilter:ApplyDynamicFilterChannelForRoot = ApplyDynamicFilterChannelForRoot.getService()
    
      

      const uIDynamicFilterFormService = DynamicFilterFormStateForRoot.getService(props.Filter)
      const routedButtonBarCleanup = RoutedButtonBarCleanupChannelForRoot.getService()
      const routeButtonBarResponse = RoutedButtonBarResponseChannelForRoot.getService()

      const triggerForm =  DynamicTriggerSaveFormForRoot.getService(props.Filter)
      
      const loaderState = LoaderStateServiceForRoot.getLoaderStateService()

      const contentToExport = ref()

      const pdfReport: PdfReport = {
        title: "",
        report: {
          columns: {
            column: []
          },
          rows: {
            rows: []
          }
        }
      }

      const report = reactive({
        pdf: pdfReport
      })

      const content = reactive({
        titleResourceId: props.TitleResourceId,
        title: props.TitleResourceId
      })

      const request: DynamicGridRequest = {
        userId: Identity.getIdentity().getValue().UserId, 
        viewId: props.ViewId , 
        detailId: props.DetailId,
        activeData: true,
        pageSize: props.PageSize,
        revierNr: props.RevierNr,
        detailList: props.DetailList,
        routeName: props.RouteName,
        detailRouteName: props.DetailRouteName,
        page: props.Page,
        form: props.Form,
        language: UserProfile.GetClientSettingsSync().language,
        allRecords: false,
        records: undefined,
        remoteId: "",
        lastRefreshDate:  RefreshServerObjects.getLastGridFormRefreshDateSync(),
        lastViewIdRefreshState: RefreshServerObjects.getLastGridFormRefreshDateSync()
      }

      const formRecords: DynamicRecord[] = [{
          teilFormularName: "",
          formularFeldname: "",
          feldTyp: "",
          istEditierbar: false,
          istPflichtfeld: false,
          istSichtbar: false,
          maxLaenge: 0,
          resourceId: "",
          teilFormularReihenfolge: 0,
          regulaererAusdruck: "",
          resourceIdErrorMeldungPflichtfeld: "",
          resourceIdErrorMeldungValidierung: "",
          resourceIdErrorMeldungMaxLaenge: "",
          layoutKlasse: "",
          isValidationError: false,
          validationMessage: "",
          value: undefined,
          tabellenFeldname: "",
          tabellenName: "",
          infoTextTitle: undefined,
          infoTextContent: undefined,
          infoResourceId: undefined,
          parentCascadeId: undefined
          }]

      const dynamicDetail = reactive({        
          apiLoadMethod: "",
          apiSaveMethod: "",
          form: "FischbuchEintragPacht",
          page: "FischbuchEintragPachtDaten",
          remoteId: "",
          loadErrorTitle: "",
          loadErrorMessage: "",
          detailData: {},
          hiddenFields: "",
          parentId: "",
          records: formRecords,
          routeName: "",    
          filter: "",
          mode: "DynamicNavigation",
          geoEditMode: "change",
          geoGpsMode: "Allowed",
          geoDisplayClass: "small",
          geoCheckInsidePolygon: true

      })


      const dynamic = useDynamicListBase(request)
      const listVisible = ref(true);
      const filterVisible = ref(false)
      const dynamicFilterVisible = ref(false)
     
      const fishBookView = "fishBookEntriesWithDetail"
      const wildBookView = "wildBookEntriesWithDetail"
      const personProductsView = "personProductsWithDetail"

      function syncSupported() {
        return props.Synced
      }

      function isToSynchronizeClass(row: DynamicGridRow) {
        if ( !props.Synced ) {
          return "sync__none"
        }

        let isSynced: boolean | undefined = false 
        isSynced = row.isSynced ?? true

        if ( isSynced) {
          return "col-2 sync__invisible"
        }
        return "col-2 sync__visible"
      }

      function isInSyncState(row: DynamicGridRow) {
        if ( !props.Synced ) {
          return true
        }

        let isSynced: boolean | undefined = false 
        isSynced = row.isSynced ?? true

        if ( isSynced) {
          return true
        }
        return false

      }

      function isNotToSynchronizeClass(row: DynamicGridRow) {
        if ( !props.Synced ) {
          return "sync__none"
        }

        let isSynced: boolean | undefined = false 
        isSynced = row.isSynced ?? true

        if ( !isSynced) {
          return "col-2 sync__invisible"
        }
        return "col-2 sync__visible"
      }


      function syncSupportedAdjustTitle() {
        if ( props.Synced) {
          return "col-6 eFj-list-card-text"
        }
        return "col-8 eFj-list-card-text"
      }


      async function load() {     
        request.language = (await (UserProfile.GetClientSettingsAsync())).language   
        content.title = await Resource.getResourceText(props.TitleResourceId)
        request.language = (await (UserProfile.GetClientSettingsAsync())).language   
        loaderState.changeLoaderState({isInProgress: true})
        await dynamic.getDynamicGridData(request, undefined)  
        loaderState.changeLoaderState({isInProgress: false})   
      }

      async function loadWithFilter(rows: Array<DynamicRecord>) {
        request.language = (await (UserProfile.GetClientSettingsAsync())).language   
        if (!rows) return
        if( rows.length <= 1) return
        loaderState.changeLoaderState({isInProgress: true})
        await dynamic.getDynamicGridData(request, rows)
        loaderState.changeLoaderState({isInProgress: false})
      }

      function isWildBook() {
        if ( props.ViewId === wildBookView ) {
          return true;
        }
        return false;

      }
  
      async function refresh() {
        await load()        
      }
  
      function onChangeSwitch(checked: boolean) {
        if ( checked ) {
            request.activeData = true
        } else {
            request.activeData = false
        }
        refresh();
      }
      const doNothing = () => {
        Out.noOperation()
      }
          

      function isListVisible() {
        if (listVisible.value && !dynamicFilterVisible.value) {
          return "show__div";
        }
        return "hide__div";
      }

      function isNotListVisibleAndDetailListVisible() {  
  
    
        if (listVisible.value || dynamicFilterVisible.value) {
          return "hide__div";
        }
        if ( props.ViewId === wildBookView ) {
          return "hide__div";
        }

        return "show__div"
      }

      function isNotListVisibleAndDetailFormVisible() {
        if( filterVisible.value ) {
          return "hide__div";
        }

        if (listVisible.value) {
          return "hide__div";
        }
        if ( props.ViewId === fishBookView ) {
          return "hide__div";
        }
        if ( props.ViewId === personProductsView ) {
          return "hide__div";
        }

        return "show__div"
      }

      function isNotListVisible() {
        if (!listVisible.value && !dynamicFilterVisible.value && !filterVisible.value) {
          return "show__div";
        }
        return "hide__div";
      }

     


      function isFilterVisible() {
        if (filterVisible.value) {
          return "row g-0 eFj-list-content-header-filter show__div";
        }
        return "row g-0 eFj-list-content-header-filter hide__div";
      }
    
        
     
     
      load()  
      
    

      async function openDetail( record: DynamicGridRow) {
        openDetailForm(record)
        if ( request.viewId === wildBookView ) return
        openDetailList(record)
      }

      //---------------------- Filtersteuerung ----------------------
      if ( props.Filter && props.Filter.length > 0 ) {     
         filterVisible.value = true   
         router.push({name: props.Filter})
         Out.pusRouterOut(`DynamicList.Filter => RouteName: ${props.Filter}` )
      }

      //__ direkter Filter
      attachedTo.changeFilterState({ attachTo: clientRouteName.value as FilterSupportedLists, clientParentRoute: clientRouteName.value})
      const onFilterHandlerSubscription = applyFilter.subscription().subscribe({
        next: (value) => {             
            if ( applyFilter.isAttached(request.viewId as FilterSupportedLists) ) {
              request.activeData = value.showActiveData
                   
              load()          
            }         
        }
      })

      //__ Dynamischen Formularfilter anzeigen verbergen
      const onUiDynamicFilterForm = uIDynamicFilterFormService.subscription().subscribe({
        next: (value) => {
          if ( value.showFilter !== undefined && uIDynamicFilterFormService.isAttached(props.Filter)) {
            listVisible.value = !value.showFilter 
            dynamicFilterVisible.value = value.showFilter           
          }
        }
      })

      const saveForm = DynamicSaveFormForRoot.getService(props.Filter)
      const onSaveForm = saveForm.subscription().subscribe({
        next: (value) => {
          if (saveForm.isAttached(props.Filter) ) {            
            if ( value.rows !== undefined) loadWithFilter( value.rows )
          }
        }
      })
      
      //---------------------- Navigation Element (Zurück Button) ----------------------
      const onRouteButtonBarResponse = routeButtonBarResponse.subscription().subscribe({
        next: (value) => {
          if ( value.targetRoute !== undefined && value.targetRoute === props.RouteName && value.topic === props.Filter )
          {
            if ( value.command === "Save") {
              triggerForm.changeFormState({formName: props.Filter, trigger: true})                                        
            }

            if ( value.command === "Save" || value.command === "Back") {
              setTimeout( () => { onNavigationClosed() } , 0)
            }
          }
        }
      })      

      async function onNavigationClosed() {                   
        routedButtonBarCleanup.changeRouteState({targetRoutes: {backTargetRoute: props.RouteName , vorwardTargetRoute: props.RouteName} })        
        filterVisible.value = true
        listVisible.value = true;               
        router.push({name: props.Filter})  
        Out.pusRouterOut(`DynamicList.onNavigationClosed => RouteName: ${props.Filter}` )

      }	  

      //--- Nachricht von der Klasse DanymicNavigation (Proxy) um das Formular zu verbergen
      const notifyDynamicList = NotifyDynamicList.getService()
      const onNotifyDynamicList = notifyDynamicList.subscription().subscribe({
        next: (value) => {
          if ( ! value ) return
          if ( value.isClosed ) closeDetail()          
        }
      })
      


      //---------------------- Detailform zur Dateneingabe ----------------------
      async function openDetailForm(record: DynamicGridRow) {        
          listVisible.value = false
          filterVisible.value = false
          let detail = props.DetailId
          if ( props.ViewId === wildBookView ) {
            if ( record.detailForm) {
             
              const dynamicDetailData: DynamicLoadFormData = {
                form: record.cell.readableIdentification?.kategorie ?? "",
                page: getStartPageFromKategorie(record.cell.readableIdentification?.kategorie ?? ""),
                filter: record.cell.readableIdentification?.kategorie ?? "",
                route: clientRouteName.value ?? "",
                mode: "DynamicNavigation",
                geoDisplayClass: "small",
                records: record.detailForm?.view,
                remoteId: record.id ?? ""
              }
              DynamicLoadFromListData.storeDynamicData(dynamicDetailData)
              DynamicListStore.SetSelectedGridRow(record)
            }
            detail = getRouteNameFromKategorie(record.cell.readableIdentification?.kategorie ?? "")
            router.push({name: detail + "Map"})
            Out.pusRouterOut(`DynamicList.dynamicDetail => RouteName: ${detail}` )
            DynamicNavigation.start("WildBook" , detail, "IconNavigationBar")           
            return
          } 
          router.push({name: detail})
          Out.pusRouterOut(`DynamicList.detail => RouteName: ${detail}` )
          toDetailFormNotfication.changeDetailState( await CreateDetailState(dynamic.grid as unknown as DynamicGrid, record, true, detail as unknown as DetailsSupported ))
      }

      function getStartPageFromKategorie(kategorie: string) : string {
        if ( ! kategorie ) return "WildbuchEintragLokalisierungJagd"
        if ( kategorie === "WildbuchEintragFallwild") return  "WildbuchEintragLokalisierungFallwild"
        if ( kategorie === "WildbuchEintragJagd") return  "WildbuchEintragLokalisierungJagd"
        if ( kategorie === "WildbuchEintragBeobachtung") return  "WildbuchEintragLokalisierungBeobachtung"
        if ( kategorie === "WildbuchEintragBeobachtungSpez" ) return  "WildbuchEintragLokalisierungBeobachtungSpezial"
        return "WildbuchEintragLokalisierungJagd"
      }

      function getRouteNameFromKategorie(kategorie: string) : string {
        if ( ! kategorie ) return "WildBookEntry"
        if ( kategorie === "WildbuchEintragFallwild") return  "WildBookFall"
        if ( kategorie === "WildbuchEintragJagd") return  "WildBookEntry"
        if ( kategorie === "WildbuchEintragBeobachtung") return  "WildBookObserve"
        if ( kategorie === "WildbuchEintragBeobachtungSpez" ) return  "WildBookObserveSpec"
        return "WildBookEntry"
      }


      const onDetailFormSubscription = onDetailFormNotfication.subscription().subscribe({
        next: (value) => {        
          if ( onDetailFormNotfication.isAttached(clientRouteName.value as unknown as DetailsSupported )) {  
           if (value.isClosed) {
            listVisible.value = true  
            filterVisible.value = true                   
            router.push({name: props.Filter})
            Out.pusRouterOut(`DynamicList.onDetailFormSubscription => RouteName: ${props.Filter}` )
           }
          }
        }
      })

      //---------------------- Detailliste ----------------------
      async function openDetailList(record: DynamicGridRow) {      
          triggerForm.changeFormState({ formName: props.Filter, trigger: false })
          listVisible.value = false
          filterVisible.value = true
          router.push({name: props.DetailId})       
          Out.pusRouterOut(`DynamicList.openDetailList => RouteName: ${props.DetailId}` )   
          toDetailListNotfication.changeParentState(await CreateParentListState(dynamic.grid as unknown as DynamicGrid, record, request  ))          
      }
      const onDetailListSubscription = onDetailListNotification.subscription().subscribe({
        next: async (value) => {   
          if ( onDetailListNotification.isAttached(clientRouteName.value as unknown as SupportedDetailLists )) {
             if ( dynamic?.grid?.result?.rows) {
              for(const row of dynamic.grid.result.rows) {
                if ( props.ViewId === fishBookView){
                  row.cell.content.title =  await resolveMainListResourceTitel(fishBookView, row.cell.content.titleResourceId ?? "", row )
                }
                if (row.id === value.row.id && row.id !== null && row.id !== undefined) {
                  row.rows = value.row.rows
                }
              }
             }
          }       
          if (value.isClosed) {
            listVisible.value = true        
            filterVisible.value = true         
            router.push({name: props.Filter})       
            Out.pusRouterOut(`DynamicList.onDetailListSubscription => RouteName: ${props.Filter}` )   
          }
        }
      })
    
      function closeDetail() {
          listVisible.value = true;   
          filterVisible.value = true
          router.push({name: props.Filter})   
          Out.pusRouterOut(`DynamicList.closeDetail => RouteName: ${props.Filter}` )   
      }

      function getImagePath( record: DynamicGridRow) {         
          return record.cell.image.imagePath ?? ''
      }

      function getImageStatusText( record: DynamicGridRow) {
          return record.cell.additional.text ?? ""
      }

      function getImageState( record: DynamicGridRow) {  
        return record.cell.image.imageState ?? ""
      }

      function getCellTitle( record: DynamicGridRow) {
        if (record?.cell?.content?.title && ( !record?.cell?.content?.groundNumber || record?.cell?.content?.groundNumber === "0" || record?.cell?.content?.groundNumber === "")) {
          return sanitize(record.cell.content.title)
        }

        return  ( record.cell.content.title ?? "") + "<br />" + "<br />" + record.cell.content.groundNumber + " " + record.cell.content.groundName


      }
    
      async function navigate(nav: any) {
        if ( ! isListVisible()) {
          closeDetail()
          return
        }
        let isNavigation = true;
        let isNext = true;
        const navigation = StateNavigationAction.Next;
        const conditions = [];        
      
        switch (nav.command) {
          case Commands.Pdf.toString(): {


            if ( !(await UserProfile.GetCurrentProfile()).online ) {
              const offlineTextTitle = await Resource.getResourceText(`Offline.NotAllowed.Title`)
              const offlineTextContent = await Resource.getResourceText(`Offline.NotAllowed.Content`)
              openDialog(store, {name: "Offline Mode", isOk: true, isCancel: false, titel: offlineTextTitle, text: offlineTextContent, open: true, type: "info"})
              return
            }

            //if(props.ViewId === wildBookView) return

            
            loaderState.changeLoaderState({isInProgress: true})

            const filterResult = LocalStore.GetForm(props.Filter)
            
            isNext = true;
            isNavigation = false;

            const clonedRequest = JSON.parse( JSON.stringify(request) )
            try {
                if ( clonedRequest && clonedRequest.revierNr && typeof(clonedRequest.revierNr) === "object" ) {
                    clonedRequest.revierNr = clonedRequest.revierNr.key                     
                }
                if ( filterResult !== undefined ) {
                    clonedRequest.dynamicFilterItems = JSON.parse( JSON.stringify(filterResult) )
                    overwriteSerializedLocalDate(filterResult, clonedRequest.dynamicFilterItems)
                    for ( const row of clonedRequest.dynamicFilterItems) {
                        try {
                            if ( row.dateValue && row.dateValue !== undefined) {
                                row.value =overWriteValueFromDateValue(row.dateValue)
                            }
                        } catch (e) {
                            Out.noOperation(e)
                        }
                    }
                }
            } catch(e) {
                Out.noOperation()
            }


            const pdfResult = await getDynamicPdfFromApi(clonedRequest)
            if ( pdfResult.report ) {
              report.pdf = pdfResult as unknown as PdfReport
              //const filename = await Resource.getResourceText()
              setTimeout(() => {
                contentToExport.value.save()
                //savePDF(contentToExport.value, {fileName: ""})
              }, 0); 
              loaderState.changeLoaderState({isInProgress: false})
            } else {
              loaderState.changeLoaderState({isInProgress: false})
              let title = await Resource.getResourceText("Report.Pdf.Empty.Title")
              let content = await Resource.getResourceText("Report.Pdf.Empty.Content")
              openDialog(store, {name: "Information", isOk: true, isCancel: false, titel: title, text: content, open: true, type: "info"})
            }


            break
          }

          case Commands.Information.toString(): {
            isNext = true;
            isNavigation = false;
            let title = await Resource.getResourceText("InfoText.Fischfang.PersonProdukte.Title")
            let content = await Resource.getResourceText("InfoText.Fischfang.PersonProdukte.Content")
            if (props.ViewId === fishBookView) {
              title = await Resource.getResourceText( "InfoText.Fischbuch.Title" )
              content = await Resource.getResourceText("InfoText.Fischbuch.Content")
            }
            if ( props.ViewId === wildBookView){
              title = await Resource.getResourceText( "InfoText.Wildbuch.Title" )
              content = await Resource.getResourceText("InfoText.Wildbuch.Content")
            }
            openDialog(store, {name: "Information", isOk: true, isCancel: false, titel: title, text: content, open: true, type: "info"})
            break
          }

          case Commands.PreviousPage.toString(): {
            isNext = false;
            conditions.push({ nameId: props.NavigationState, value: "BackToParent" });
            break;
          }
        }
  
        if (isNavigation) {
          if (isNext) {
            stateMachine.next({navigation: navigation,conditions: conditions,},{lookahead: false,});
            changeNavigation(store);
            return 
          }
          stateMachine.previous({navigation: navigation,conditions: conditions,},{lookahead: false,});
          changeNavigation(store);
        }
      }

      async function synchronize(record: DynamicGridRow) {
        let response: SyncStateResponse | undefined = undefined
        if ( props.ViewId === wildBookView){
          response = await syncWildbook(record, "command")
        } else {
          response = await syncFishbook({ parentRow: record, childRow: undefined }, "command")
        }
        ChangeList(record)

        if ( response && response.parentRow && response.parentRow.syncState) {
          if ( !response.parentRow.isSynced) {
            const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
            notficationState.resetNotificationState()
            if ( response.parentRow.syncState.stateResourceId === "Sync.State.Failed") {
              notficationState.changeNotificationState({
              isNotification: true, text: await Resource.getResourceText("Sync.State.Failed.Content"), title: await Resource.getResourceText("Sync.State.Failed.Title")})
              return
            }
            notficationState.changeNotificationState({
              isNotification: true, text: await Resource.getResourceText("Sync.State.NotSynchronized.Content"), title: await Resource.getResourceText("Sync.State.NotSynchronized.Title")})
          }
        }
      }

      async function ChangeList(record: DynamicGridRow) {
        if ( !record  ) {
          return
        }

        let isSynced = false
        let isFailed = false
        const dbRecord = await findLocalFishingParentRecordById(record.id ?? "")
        if( dbRecord && dbRecord.rows && dbRecord.rows.length > 0 && record && record.rows && record.rows.length > 0 ) {
          isSynced = true
          for (const dbRecordDetailRow of dbRecord.rows) {
            let found  = false
            for( const row of record.rows) {
              if ( dbRecordDetailRow.id === row.id ) {
                found = true
                row.isSynced = dbRecordDetailRow.isSynced
                if ( ! dbRecordDetailRow.isSynced) {
                  isSynced = false
                }
                row.cell.content.title = await resolveSubListResourceTitel(dbRecordDetailRow?.cell.content.titleResourceId, dbRecordDetailRow!)
                row.cell.image.imageState = dbRecordDetailRow?.cell.image.imageState
                row.cell.image.imageType = dbRecordDetailRow?.cell.image.imageState
                row.cell.image.imagePath = dbRecordDetailRow?.cell.image.imagePath
                row.isSynced = dbRecordDetailRow?.isSynced
                if ( row.syncState) {
                    row.syncState.stateResourceId = dbRecordDetailRow?.syncState?.stateResourceId ?? ""
                    row.syncState.isSynched = dbRecordDetailRow?.syncState?.isSynched ?? false
                    row.syncState.errorMessageResourceId = dbRecordDetailRow?.syncState?.errorMessageResourceId ?? ""
                    if ( row.syncState.errorMessageResourceId && row.syncState.errorMessageResourceId.length > 0 ) {
                      isFailed = true
                    }
                }
                
                // ****************************

                if( row.detailForm && row.detailForm.view && dbRecordDetailRow.detailForm && dbRecordDetailRow.detailForm.view) {
                  const form = row.detailForm?.view
                  const dbForm = dbRecordDetailRow.detailForm?.view
                  if ( form && dbForm ) {
                      for( const dbFormRow of dbForm) {
                          for( const formRow of form) {
                              if ( formRow.formularFeldname && dbFormRow.formularFeldname &&  formRow.formularFeldname === dbFormRow.formularFeldname ) {
                                  formRow.resourceIdServerValidation = dbFormRow.resourceIdServerValidation
                              }
                          }
                      }
                  }
                }

                // ****************************

              }
            }
          }
        }
        if( dbRecord && record ) {
          if ( isFailed ) {
            if ( props.ViewId === wildBookView){
              record.cell.content.title =  await resolveMainListResourceTitel(wildBookView, dbRecord.cell.content.titleResourceId ?? "", dbRecord )
            } else {
              record.cell.content.title =  await resolveMainListResourceTitel(fishBookView, dbRecord.cell.content.titleResourceId ?? "", dbRecord )
            }
            record.cell.image.imageState = "failedsync"
            record.cell.image.imageType = "failedsync"
            record.cell.image.imagePath = "img/icons/failedsync.svg"
            record.isSynced = false
            if (  record.syncState) {
                  record.syncState.stateResourceId = "Sync.State.Failed"
                  record.syncState.isSynched = false
                  record.syncState.errorMessageResourceId = dbRecord?.syncState?.errorMessageResourceId ?? ""
            } 
 
            return
          }


          if ( ! isSynced) {
            if ( props.ViewId === wildBookView){
              record.cell.content.title =  await resolveMainListResourceTitel(wildBookView, dbRecord.cell.content.titleResourceId ?? "", dbRecord )
            } else {
              record.cell.content.title =  await resolveMainListResourceTitel(fishBookView, dbRecord.cell.content.titleResourceId ?? "", dbRecord )
            }
            record.cell.image.imageState = "notsynced"
            record.cell.image.imageType = "notsynced"
            record.cell.image.imagePath = "img/icons/notsynced.svg"
            record.isSynced = false
            if (  record.syncState) {
                  record.syncState.stateResourceId = "Sync.State.NotSynced"
                  record.syncState.isSynched = false
                  record.syncState.errorMessageResourceId = ""
            } 
            return          
          }

          if ( props.ViewId === wildBookView){
              record.cell.content.title =  await resolveMainListResourceTitel(wildBookView, dbRecord.cell.content.titleResourceId ?? "", dbRecord )
          } else {
            record.cell.content.title =  await resolveMainListResourceTitel(fishBookView, dbRecord.cell.content.titleResourceId ?? "", dbRecord )
          }
          record.cell.image.imageState = dbRecord?.cell.image.imageState
          record.cell.image.imageType = dbRecord?.cell.image.imageState
          record.cell.image.imagePath = dbRecord?.cell.image.imagePath
          record.isSynced = dbRecord.isSynced
          if (  record.syncState) {
                record.syncState.stateResourceId = dbRecord?.syncState?.stateResourceId ?? ""
                record.syncState.isSynched = dbRecord?.syncState?.isSynched ?? false
                record.syncState.errorMessageResourceId = dbRecord?.syncState?.errorMessageResourceId ?? ""
          }                
        }
         
        
      }

      
      const listNotify = BusinessChanges.getService()
      const onListNotify = listNotify.subscription().subscribe({
        next: async (value) => {
          if (! value || ! value.action  || ! value.row || ! dynamic.grid.result.rows) return  
          if ( value.action === "hided") {
              for(const row of dynamic.grid.result.rows) {
                if( isGridRowEqual(row,value.row)) {
                  if ( row.syncState)
                    row.isSynced = false
                    if ( row.syncState) {
                        row.syncState.isSynched = false
                        row.syncState.stateResourceId = "Sync.State.Hidden"
                    }
                }
              }
              return
            }          
          
            if ( value.action === "delete") {
              for(const row of dynamic.grid.result.rows) {
                if( isGridRowEqual(row,value.row)) {
                  if ( row.syncState)
                    row.syncState.stateResourceId = "Sync.State.Delete"
                    row.isSynced = value.row.isSynced
                    if ( row.syncState && value.row.syncState) {
                        row.syncState.isSynched = value.row.syncState.isSynched
                        row.syncState.stateResourceId = value.row.syncState.stateResourceId
                    }
                    if ( row.cell.image && value.row.cell.image) {
                        row.cell.image.imageType = value.row.cell.image.imageType
                        row.cell.image.imagePath = value.row.cell.image.imagePath
                        row.cell.image.imageState = value.row.cell.image.imageState
                    }

                  //row.isVisible = false
                }
              }
              return
            }

            for(const row of dynamic.grid.result.rows) {
              if( isGridRowEqual(row,value.row)) {
                row.cell.content.title =await resolveMainListResourceTitel("wildBookEntriesWithDetail", await JagdRevierTitel.getTitelFromForm(value.row.detailForm) ?? "" , value.row )

                row.isSynced = value.row.isSynced
                if ( row.syncState && value.row.syncState) {
                    row.syncState.isSynched = value.row.syncState.isSynched
                    row.syncState.stateResourceId = value.row.syncState.stateResourceId
                }
                if ( row.cell.image && value.row.cell.image) {
                    row.cell.image.imageType = value.row.cell.image.imageType
                    row.cell.image.imagePath = value.row.cell.image.imagePath
                    row.cell.image.imageState = value.row.cell.image.imageState
                }

                if ( row.detailForm && row.detailForm.view && value.row.detailForm && value.row.detailForm.view) {
                  for ( const detailFormRow of row.detailForm.view) {
                    for (const valueFormRow of value.row.detailForm.view) {
                      if ( detailFormRow.formularFeldname &&  valueFormRow.formularFeldname && valueFormRow.value) {
                        if ( detailFormRow.formularFeldname == valueFormRow.formularFeldname) {
                          detailFormRow.value = valueFormRow.value
                        }
                      }
                    }
                  }
                }

              }
            }            
          }
        
      })

      function isGridRowEqual(a: DynamicGridRow , b: DynamicGridRow) : boolean {
        if (a.rowId && b.rowId && a.rowId.length > 0 && a.rowId === b.rowId) return true
        if (a.id && b.id && a.id.length > 0 && a.id === b.id) return true
        if (a.id && b.rowId && a.id.length > 0 && a.id === b.rowId) return true
        if (b.id && a.rowId && b.id.length > 0 && b.id === a.rowId) return true
        return false
      }

      function isGridRowVisible(row: DynamicGridRow) {       
        if ( row.syncState ) {
          if ( row.syncState.stateResourceId === "Sync.State.Hidden" ) return false
        }

        return true
      }
      
      onMounted(() => {        
        if ( !DynamicNavigation.isNavigationEnabled()) {
          if (currentState.navigation) {
            try {
              DynamicNavigation.saveLastStaticNavigationState([currentState.navigation?.nav1,currentState.navigation?.nav2,currentState.navigation?.nav3,currentState.navigation?.nav4])
            } catch(e) {
              Out.noOperation("")
            }
          }

        }

        if (!DynamicNavigation.isAssigned("DynamicList")) return       
        
        LocalStore.DisableFormNavigationOnce()
        unsubscribe = store.subscribe((mutation) => {
          if (mutation.type === "navigationState/clickNavigationItem1") {
            navigate(currentState.navigation?.nav1);
          }
          if (mutation.type === "navigationState/clickNavigationItem2") {
            navigate(currentState.navigation?.nav2);
          }
          if (mutation.type === "navigationState/clickNavigationItem3") {
            navigate(currentState.navigation?.nav3);
          }
          if (mutation.type === "navigationState/clickNavigationItem4") {
            navigate(currentState.navigation?.nav4);
          }
        });
      });
  
      onUnmounted(() => {
        GeoStoreData.enableEditMode()
        onListNotify.unsubscribe()
        LocalStore.EnableFormNavigationOnce()
        attachedTo.changeFilterState({ attachTo: undefined as FilterSupportedLists, clientParentRoute: undefined})
        applyFilter.changeFilterState( { showActiveData: true, attachTo: undefined })
        saveForm.changeFormState({attachTo: undefined, formName: "", rows: undefined })
        onDetailFormNotfication.changeDetailState( createIntitialFormDetailState() )
        onDetailListNotification.changeDetailState( createIntitialListDetailState() )

        onDetailFormSubscription.unsubscribe()
        onFilterHandlerSubscription.unsubscribe()        
        onDetailListSubscription.unsubscribe()
        onUiDynamicFilterForm.unsubscribe()
        onRouteButtonBarResponse.unsubscribe()
        onSaveForm.unsubscribe()
        onNotifyDynamicList.unsubscribe()

        triggerForm.changeFormState({formName: '', trigger: false})

        unsubscribe();
      });
  
      return {             
        dynamic,        
        listVisible,
        content,
        request,
        filterVisible,
        dynamicFilterVisible,
        contentToExport,
        report,
        onChangeSwitch,
        isListVisible,
        openDetail,
        closeDetail,       
        getImagePath,
        getImageState ,
        getImageStatusText,
        getCellTitle,
        isFilterVisible,
        isNotListVisible,
        synchronize,
        isToSynchronizeClass,
        syncSupported,
        syncSupportedAdjustTitle,
        isNotListVisibleAndDetailListVisible,
        isNotListVisibleAndDetailFormVisible,
        doNothing,
        dynamicDetail,
        clientRouteName,
        isGridRowVisible,
        isWildBook,
        isNotToSynchronizeClass,
        isInSyncState
      };
    },
  });
