
import { defineComponent, reactive } from 'vue';
import DynamicList from '@/components/Shared/dynamicList/DynamicList.vue';
import {FishingEdit} from "@/components/fishing/business/FishingEdit"
import { FishingRecordParent } from '../business/FishingRecordParent';

export default defineComponent ({
  name: "FishBook",
  inheritAttrs: false,
  components: {     
    DynamicList
  },
  setup() {
      const request = reactive({
          NavigationState: "FishBook",
          ViewId: "fishBookEntriesWithDetail",          
          DetailId: "FishbookDetailRecord",
          RevierNr: FishingEdit.getEditFishingStateInfo(false).fischereiRevier as unknown as string,
          PageSize: 5,
          TitleResourceId: "Fischfang.DetailListe.Titel",
          Filter: "FishBookFilter",
          DetailList: true,
          RouteName: "FishBook",
          DetailRouteName: "FishbookDetailRecord",
          Form: "FischbuchEintragPacht",
          Page: "FischbuchEintragPachtDaten",     
          Synced: true  
      });

     
      return {
          request
      }
  }
})
