import { DynamicGridRowParentChild } from "../dynamicList/DynamicListTypes"
import { DynamicFormBusinessCasesFishing } from "./DynamicFormBusinessCasesFishing"
import { DynamicFormBusinessCasesHunting } from "./DynamicFormBusinessCasesHunting"
import { DynamicDataSet, DynamicRecord } from "./DynamicFormData"

export class DynamicFormBusinessCases {

    private fishing: DynamicFormBusinessCasesFishing = new DynamicFormBusinessCasesFishing()
    private hunting: DynamicFormBusinessCasesHunting = new DynamicFormBusinessCasesHunting()

    public static handleNachsucheOnChange(viewId: string , componentId: string, resourceId: string, rows : DynamicRecord[]) : void {
        if ( componentId.toLocaleLowerCase() === "WildbuchNachsuche".toLocaleLowerCase() && (viewId.indexOf("WildbuchEintragJagd") >= 0 || viewId.indexOf("WildbuchEintragFallwild") >= 0 ) ) {
            if( resourceId.toLocaleLowerCase() === "WildbuchNachsuche.Erfolgreich".toLocaleLowerCase() || resourceId.toLocaleLowerCase() === "WildbuchNachsuche.NichtErfolgreich".toLocaleLowerCase() ) {
                DynamicFormBusinessCases.showOrHideNachsuche(rows, true)
                return
            }

            DynamicFormBusinessCases.showOrHideNachsuche(rows, false)
        }        
    }


    public static handleNachsucheOnLoad(viewId: string , rows : DynamicRecord[]) : void {
        if ( viewId.indexOf("WildbuchEintragJagd") >= 0 || viewId.indexOf("WildbuchEintragFallwild") >= 0 ) {

            let resourceId = ""

            for( const row of rows ) {
                if(row.value && row.namedValueList && row.formularFeldname && row.formularFeldname.toLocaleLowerCase() === "Nachsuche".toLocaleLowerCase()) {
                    
                    for( const item of row.namedValueList) {
                        if ( item.key === row.value) {
                            resourceId = item.resourceId ?? ""
                        }
                    }
                    break
                }
            }


            if( resourceId.toLocaleLowerCase() === "WildbuchNachsuche.Erfolgreich".toLocaleLowerCase() || resourceId.toLocaleLowerCase() === "WildbuchNachsuche.NichtErfolgreich".toLocaleLowerCase() ) {
                DynamicFormBusinessCases.showOrHideNachsuche(rows, true)
                return
            }

            DynamicFormBusinessCases.showOrHideNachsuche(rows, false)
          }        
    }


    private static showOrHideNachsuche(rows : DynamicRecord[] , show: boolean) : void {
        for( const row of rows ) {
            if(row.formularFeldname === "PersonNachsucheAnzeige" || row.formularFeldname === "PersonNachsucheEingabe") {
                row.istSichtbar = show
                if ( !show) row.value = ""
            }
        }
    }


    public async getRemoteFormOverwriteFormWithBusinessCase(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<any> {
        if ( this.isFishBook(reactiveData.viewId))
            return await this.fishing.getRemoteFormOverwriteFormWithBusinessCase(reactiveData,clientRouteName)
        if ( this.isWildBook(reactiveData.viewId))
            return await this.hunting.getRemoteFormOverwriteFormWithBusinessCase(reactiveData,clientRouteName)            
    }

    public async deleteDynamicGridRow(reactiveData: DynamicDataSet, clientRouteName: string, rowId: string) : Promise<DynamicGridRowParentChild | undefined> {
        if ( this.isFishBook(reactiveData.viewId))
            return await this.fishing.deleteDynamicGridRow(reactiveData,clientRouteName, rowId)
         if ( this.isWildBook(reactiveData.viewId))            
            return await this.hunting.deleteDynamicGridRow(reactiveData,clientRouteName, rowId)
        }

    public async saveRemoteFormOverwriteFormWithBusinessCase(reactiveData: DynamicDataSet, clientRouteName: string) : Promise<DynamicGridRowParentChild | undefined> {
        if ( this.isFishBook(reactiveData.viewId))
            return await this.fishing.saveRemoteFormOverwriteFormWithBusinessCase(reactiveData,clientRouteName)
        if ( this.isWildBook(reactiveData.viewId))            
            return await this.hunting.saveRemoteFormOverwriteFormWithBusinessCase(reactiveData,clientRouteName)
    }


    private isFishBook(viewId: string) : boolean {
        switch( viewId) {
            case "HomeFischerei":
                return true
        
            case "FischbuchEintragPachtrevier":
                return true
        
            case "FischbuchEintragPatentrevier":
                return true
            case "FischbuchEintragLinthkanal":
                return true
           }
           return false
    }

    private isWildBook(viewId: string) : boolean {
        switch( viewId) {
            case "WildbuchEintragJagd":
                return true
            case "WildbuchEintragFallwild":
                return true
            case  "WildbuchEintragBeobachtung": 
                return true
            case  "WildbuchEintragBeobachtungSpez": 
                return true
            }
           return false
    }


}