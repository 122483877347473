import { DynamicGridRow, DynamicGridRowParentChild, SyncFishbookAction, SyncStateRequest, SyncStateResponse } from "@/components/Shared/dynamicList/DynamicListTypes";
import { AsyncRestClient } from "../backend/async/AsyncRestClient";
import { findLocalFishingDetailRecordById } from "@/components/fishing/business/FishingAdd";
import { Out } from "../frontent/clientMessage";
import { addOrReplaceRecord, fishbookTable, loadAllRecords } from "../database/asyncDb";
import { Identity } from "../backend/userIdentity";
import { DynamicRecord } from "@/components/Shared/Dynamicform/DynamicFormData";
import { Navigate } from "../backend/async/Navigate";
import { overwriteSerializedLocalDate, overwriteSerializedSingleLocalDate } from "../functional/datetimehelper";



export async function syncFishbook(parentDetailRow: DynamicGridRowParentChild, action: SyncFishbookAction) : Promise<SyncStateResponse> {
    
    const request: SyncStateRequest = {
        userId: Identity.getIdentity().getValue().UserId,
        parentRow: parentDetailRow.parentRow,
        action: action
    }
    
    let response: SyncStateResponse = {
        success: true,
        offline: false,
        errorResourceId: "",
        parentRow: parentDetailRow.parentRow
    }

    const childRows = await getChildRows(parentDetailRow, action)
    if (childRows) {
        request.childRows = childRows
        response.childRows = childRows
    }

    try {
        if( Navigate.IsLocked() ) {
            response.success = false
            response.errorResourceId = "error.response.server"
            return response
        }

        const client = AsyncRestClient.Create(true);
        if (client.isFailure) throw new Error("Error.syncFishbook");
        const result = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/sync/syncFishbook", JSON.stringify(request));  
    
        if (result.isFailure) {
            response.success = false
            response.errorResourceId = "error.response.server"
            return response
        }           

        const tempResult = result.getValue() as unknown as SyncStateResponse
        const apiResult = JSON.parse(JSON.stringify(result.getValue())) 
        response = apiResult as unknown as SyncStateResponse

        if ( tempResult.parentRow && tempResult.parentRow.detailForm && tempResult.parentRow.detailForm.view) { 
            overwriteSerializedLocalDate(tempResult.parentRow.detailForm?.view , response.parentRow.detailForm?.view)
        }

        if ( tempResult.childRows && tempResult.childRows.length > 0) {
            for(const detailRow of tempResult.childRows) {
                for (const responseDetail of response.childRows!) {
                    if ( detailRow.id === responseDetail.id) {
                        if ( detailRow.detailForm && detailRow.detailForm.view && responseDetail.detailForm &&  responseDetail.detailForm.view ) {
                            overwriteSerializedLocalDate(detailRow.detailForm?.view, responseDetail.detailForm?.view)
                        }
                    }
                }
            }
        }

        

        } catch(e) {
            response.success = false
            response.errorResourceId = "error.response.server"
            return response
        }
       
        if ( ! response.success ) {
            return response
        }
       
        if ( response.offline ) {            
            const offlineResponse: SyncStateResponse = {
                success: true,
                offline: true,
                errorResourceId: "",
                parentRow: parentDetailRow.parentRow
            }
            if (childRows) {
                offlineResponse.childRows = childRows
            }

            return offlineResponse
        }

        await saveResponseToLocalStore(action, response)
        
    return response
}

async function saveResponseToLocalStore( action: SyncFishbookAction, response: SyncStateResponse) {
    const dataRows = await loadAllRecords(fishbookTable) as any
    if ( dataRows === undefined ) return 
    for(const item of dataRows) {
        const parentRow = item.row as DynamicGridRow
        if ( parentRow.id === response.parentRow.id ) {
            if ( !parentRow.syncState?.isSynched || !response.parentRow.isSynced) {
                if ( !parentRow.rowId || parentRow.rowId.length <= 0) {
                    parentRow.rowId = response.parentRow.rowId
                }
                parentRow.isSynced = response.parentRow.isSynced
                parentRow.cell.image.imageState = response.parentRow.cell.image.imageState
                parentRow.cell.image.imageType = response.parentRow.cell.image.imageState ?? ""
                parentRow.cell.image.imagePath = response.parentRow.cell.image.imagePath

                if( parentRow.syncState ) {
                    parentRow.syncState.isSynched = response.parentRow.syncState?.isSynched ?? false
                    parentRow.syncState.stateResourceId = response.parentRow.syncState?.stateResourceId ?? ""
                    parentRow.syncState.errorMessageResourceId = response.parentRow.syncState?.errorMessageResourceId ?? ""
                }
            }
            if ( parentRow.rows && parentRow.rows.length > 0 && response.childRows && response.childRows.length > 0) {
                for(const detailRow of parentRow.rows) {
                    for (const responseDetail of response.childRows) {
                        if ( (!detailRow.isSynced || !responseDetail.isSynced) && detailRow.id === responseDetail.id) {
                            if ( !detailRow.rowId || detailRow.rowId.length < 1) {
                                detailRow.rowId = responseDetail.rowId
                            }
                            detailRow.isSynced = responseDetail.isSynced
                            detailRow.cell.image.imageState = responseDetail.cell.image.imageState
                            detailRow.cell.image.imageType = responseDetail.cell.image.imageState
                            detailRow.cell.image.imagePath = responseDetail.cell.image.imagePath
                            if ( detailRow.detailForm && detailRow.detailForm.view && responseDetail.detailForm &&  responseDetail.detailForm.view ) {
                                assignFormValues(detailRow.detailForm?.view, responseDetail.detailForm?.view)
                            }

                            if( detailRow.syncState ) {
                                detailRow.syncState.isSynched = responseDetail.syncState?.isSynched ?? false
                                detailRow.syncState.stateResourceId = responseDetail.syncState?.stateResourceId ?? ""
                                detailRow.syncState.errorMessageResourceId = responseDetail.syncState?.errorMessageResourceId ?? ""
                            }
                            
                        }    
                    }
                }    
            }

            if ( parentRow.rows && parentRow.rows.length > 0 && !response.childRows && response && response.parentRow &&  response.parentRow.rows) {
                for(const detailRow of parentRow.rows) {
                    for (const responseDetail of response.parentRow.rows) {
                        if ( (!detailRow.isSynced || !responseDetail.isSynced) && detailRow.id === responseDetail.id) {
                            if ( !detailRow.rowId || detailRow.rowId.length < 1) {
                                detailRow.rowId = responseDetail.rowId
                            }
                            detailRow.isSynced = responseDetail.isSynced
                            detailRow.cell.image.imageState = responseDetail.cell.image.imageState
                            detailRow.cell.image.imageType = responseDetail.cell.image.imageState
                            detailRow.cell.image.imagePath = responseDetail.cell.image.imagePath
                            if ( detailRow.detailForm && detailRow.detailForm.view && responseDetail.detailForm &&  responseDetail.detailForm.view ) {
                                assignFormValues(detailRow.detailForm?.view, responseDetail.detailForm?.view)
                            }

                            if( detailRow.syncState ) {
                                detailRow.syncState.isSynched = responseDetail.syncState?.isSynched ?? false
                                detailRow.syncState.stateResourceId = responseDetail.syncState?.stateResourceId ?? ""
                                detailRow.syncState.errorMessageResourceId = responseDetail.syncState?.errorMessageResourceId ?? ""
                            }
                            
                        }    
                    }
                }    
            }


            if (parentRow.rows && parentRow.rows.length > 0  ) {
                let synced = true
                let failed = false
                for(const detailRow of parentRow.rows) {
                    if (!detailRow.isSynced) {
                        synced = false
                       
                    }
                    if ( detailRow.syncState?.stateResourceId === "Sync.State.Failed") {
                        failed = true
                    }
                }
                if ( parentRow.rowId && parentRow.rowId.length <= 0) {
                    parentRow.rowId = response.parentRow.rowId
                }
                if ( synced) {
                    
                    parentRow.isSynced = true
                    parentRow.cell.image.imageState = "synced"
                    parentRow.cell.image.imageType = "synced"
                    parentRow.cell.image.imagePath = "img/icons/synced.svg";
    
                    if( parentRow.syncState ) {
                        parentRow.syncState.isSynched = true
                        parentRow.syncState.stateResourceId = "Sync.State.Read"
                        parentRow.syncState.errorMessageResourceId =  ""
                    }
                } else {

                    if ( failed) {
                        parentRow.isSynced = false
                        parentRow.cell.image.imageState = "failedsync"
                        parentRow.cell.image.imageType = "failedsync"
                        parentRow.cell.image.imagePath = "img/icons/failedsync.svg";
        
                        if( parentRow.syncState ) {
                            parentRow.syncState.isSynched = false
                            parentRow.syncState.stateResourceId = "Sync.State.Failed"
                            parentRow.syncState.errorMessageResourceId =  ""
                        }
                    } else {
                        parentRow.isSynced = false
                        parentRow.cell.image.imageState = "notsynced"
                        parentRow.cell.image.imageType = "notsynced"
                        parentRow.cell.image.imagePath = "img/icons/notsynced.svg";
        
                        if( parentRow.syncState ) {
                            parentRow.syncState.isSynched = false
                            parentRow.syncState.stateResourceId = "Sync.State.NotSynced"
                            parentRow.syncState.errorMessageResourceId =  ""
                        }    
                    }
                }
            }

            await addOrReplaceRecord(fishbookTable, {id: parentRow.id, row: parentRow})            
        }       
    }
    return
}

function assignFormValues( to: DynamicRecord[], from: DynamicRecord[] ) {
    for( const toItem of to) {
        toItem.resourceIdServerValidation = ""
    }

    for( const fromItem of from) {
        for( const toItem of to) {
            if ( fromItem.resourceIdServerValidation && fromItem.resourceIdServerValidation.length > 0) {
                if ( fromItem.formularFeldname === toItem.formularFeldname) {
                    toItem.resourceIdServerValidation = fromItem.resourceIdServerValidation
                }
            }
        }
    }
}


async function getChildRows(parentDetailRow: DynamicGridRowParentChild, action: SyncFishbookAction) : Promise<DynamicGridRow[] | undefined> {
    
    const childRows: DynamicGridRow[] = []

    if ( action === "startChange") return
    if( action === "startNew") return
    if( action === "stop") return
    if( action === "changedetail" && parentDetailRow.childRow) {
        childRows.push(parentDetailRow.childRow)
        return childRows
    }
    if( action === "deletedetail" && parentDetailRow.childRow) {
        childRows.push(parentDetailRow.childRow)
        return childRows
    }

    if( action === "command") {
        const parentRowAndChild = await findLocalFishingDetailRecordById("", parentDetailRow.parentRow.id ?? "")
        if ( parentRowAndChild && parentRowAndChild.parentRow && parentRowAndChild.parentRow.rows ) {
            for(const childRow of parentRowAndChild.parentRow.rows) {
                childRows.push(childRow)
            }
            return childRows
        }
    }
    
    return
}