import { DynamicGrid, DynamicGridRequest, DynamicGridRow } from "@/components/Shared/dynamicList/DynamicListTypes";
import { AsyncRestClient } from "../backend/async/AsyncRestClient";
import { ConvertUtcStringToDate, getDateWithoutTime, isDifferentAppSynchronId, makeValidUtcString, overwriteSerializedLocalGridDate } from "../functional/datetimehelper";
import { addOrReplaceRecord, fishbookTable, loadAllRecords } from "../database/asyncDb";
import { Identity } from "../backend/userIdentity";
import { UserProfile } from "../backend/userProfile";
import { RefreshServerObjects } from "../resource/RefreshServerObjects";
import { Navigate } from "../backend/async/Navigate";


export async function reSyncFishbookApiTest() : Promise<DynamicGrid | undefined> {
    console.log("")
    return undefined
}

export async function reSyncFishbookApi() : Promise<DynamicGrid | undefined> {
    const request: DynamicGridRequest = {
        userId: Identity.getIdentity().getValue().UserId,
        viewId: "fishBookEntriesWithDetail",
        activeData: true,
        pageSize: 1,
        revierNr: "",
        routeName: "FishBook",
        detailRouteName: "FishbookDetailRecord",
        page: "FischbuchEintragPachtDaten",
        form: "FischbuchEintragPacht",
        language: (await UserProfile.GetClientSettingsAsync()).language,
        detailId: 'FishbookDetailRecord',
        detailList: true,
        allRecords: true,
        lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
        lastViewIdRefreshState: await RefreshServerObjects.getLastGridFishBookRefreshDate()
    }
    return await reSyncFishbookGrid(request)
}

export async function reSyncFishbookGrid(request: DynamicGridRequest) : Promise<DynamicGrid | undefined> {
    let gridObject : DynamicGrid = {userId: request.userId , viewId: request.viewId}
    if ( gridObject.viewId !== "fishBookEntriesWithDetail" && gridObject.rows) {
        return
    }
    request.lastRefreshDate = await RefreshServerObjects.getLastGridFormRefreshDate()

    if (request.viewId === "personProductsWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridProductsRefreshDate()
    }
    if (request.viewId === "wildBookEntriesWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridWildBookRefreshDate()
    }
    if (request.viewId === "fishBookEntriesWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridFishBookRefreshDate()
    }

    if( Navigate.IsLocked() ) {
        return undefined
    }

    const client = AsyncRestClient.Create(true);
    if (client.isFailure) throw new Error("Error.getDynamicGridData");
    const result = await client
        .getValue()
        .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/grid/loadDynamicGridData", JSON.stringify(request));  

    if (result.isFailure) return undefined
    try {
        gridObject = JSON.parse(JSON.stringify(result.getValue())) as unknown as DynamicGrid 
        overwriteSerializedLocalGridDate(result.getValue() as unknown as DynamicGrid , gridObject)
    } catch(e) {
        return undefined
    }          
   
    if ( ! (gridObject.isModified ?? true) )
        return undefined

    if(gridObject && gridObject.rows && gridObject.rows.length > 0) {
        if (request.viewId === "personProductsWithDetail") {
            await RefreshServerObjects.addLastGridProductsRefreshDate(gridObject.lastRefreshDate ?? RefreshServerObjects.getCurrentLastRefreshDate())

        }
        if (request.viewId === "wildBookEntriesWithDetail") {
            await RefreshServerObjects.addLastGridWildBookRefreshDate(gridObject.lastRefreshDate ?? RefreshServerObjects.getCurrentLastRefreshDate())

        }
        if (request.viewId === "fishBookEntriesWithDetail") {
            await RefreshServerObjects.addLastGridFishBookRefreshDate(gridObject.lastRefreshDate ?? RefreshServerObjects.getCurrentLastRefreshDate())

        }
    }

    return await reSyncFishbook(gridObject)
}

export async function reSyncFishbook(gridObject: DynamicGrid | undefined) : Promise<DynamicGrid | undefined> {    
    if ( !gridObject ||  gridObject.viewId !== "fishBookEntriesWithDetail" || !gridObject.rows) {
        return
    }

    
    if (gridObject && gridObject.rows) {
        for (const row of gridObject.rows) {
            if (row.cell.readableIdentification?.startDate && row.cell.readableIdentification?.startDate.length > 0) {
                row.cell.readableIdentification.startDate = makeValidUtcString(row.cell.readableIdentification?.startDate);
            }
            if (row.cell.readableIdentification?.stopDate && row.cell.readableIdentification?.stopDate.length > 0) {
                row.cell.readableIdentification.stopDate = makeValidUtcString(row.cell.readableIdentification?.stopDate);
            }
            if (row.cell.readableIdentification?.startDateForNextDetail && row.cell.readableIdentification?.startDateForNextDetail.length > 0) {
                row.cell.readableIdentification.startDateForNextDetail = makeValidUtcString(row.cell.readableIdentification?.startDateForNextDetail);
            }
            

            if ( row.rows && row.rows.length > 0) {
                for ( const detailRow of row.rows) {
                    if (detailRow.cell.readableIdentification?.startDate && detailRow.cell.readableIdentification?.startDate.length > 0) {
                        detailRow.cell.readableIdentification.startDate = makeValidUtcString(detailRow.cell.readableIdentification?.startDate);
                    }
                    if (detailRow.cell.readableIdentification?.stopDate && detailRow.cell.readableIdentification?.stopDate.length > 0) {
                        detailRow.cell.readableIdentification.stopDate = makeValidUtcString(detailRow.cell.readableIdentification?.stopDate);
                    }
                }
            }
        }
        const localItems = await loadAllRecords(fishbookTable)
        if ( localItems && localItems.length > 0 ) {
            const localRows: DynamicGridRow[] = []
            
            for( const localItem of localItems as any) {
                localRows.push(localItem.row as DynamicGridRow)
            }


            await reSyncRows(gridObject , localRows as DynamicGridRow[])
        } else {
            for (const row of gridObject.rows) { 
                await addOrReplaceRecord(fishbookTable, {id: row.rowId, row})   
            }
        }       
    }

    const rows = []
    const dataRows = await loadAllRecords(fishbookTable)
    if ( dataRows === undefined ) return  gridObject

    for ( const item of dataRows as any ) {
      rows.push( item.row  )
    }

    gridObject.rows = rows
    return gridObject

}

// *******************************    master rows only // startpunkt   *************************************************
async function reSyncRows( grid: DynamicGrid , localRows: DynamicGridRow[] ) : Promise<DynamicGrid | undefined> {
    if ( !grid  || !grid.rows) {
        return
    }
    for ( const apiRow of grid.rows) { 
        let localRow = findlocalMasterRowBy_Revier_Patent_DayDate(apiRow,localRows)
        if ( !localRow ) {  
            localRow = await findlocalRow(apiRow, localRows)
            if (localRow) {
                if (isDifferentAppSynchronId(localRow.appSynchronId,apiRow.appSynchronId)) {
                    localRow.appSynchronId = apiRow.appSynchronId
                    if ( localRow.cell && apiRow.cell && localRow.cell.readableIdentification && apiRow.cell.readableIdentification && apiRow.cell.readableIdentification.startDate) {
                        localRow.cell.readableIdentification.startDate =  makeValidUtcString(apiRow.cell.readableIdentification.startDate)
                    }
                    
                    if ( localRow.cell && apiRow.cell && localRow.cell.readableIdentification && apiRow.cell.readableIdentification && apiRow.cell.readableIdentification.stopDate) {
                        localRow.cell.readableIdentification.stopDate =  makeValidUtcString(apiRow.cell.readableIdentification.stopDate)
                    }
                   
                    localRow = await resyncDetailRows(apiRow,localRow)
                    if (localRow) {
                        await addOrReplaceRecord(fishbookTable, {id: localRow.id, row: localRow}) 
                    }
                }
            } else {
                await addOrReplaceRecord(fishbookTable, {id: apiRow.rowId, row: apiRow}) 
            }
        } else {


            if (isDifferentAppSynchronId(localRow.appSynchronId,apiRow.appSynchronId)) {
                localRow.appSynchronId = apiRow.appSynchronId
                if ( localRow.cell && apiRow.cell && localRow.cell.readableIdentification && apiRow.cell.readableIdentification && apiRow.cell.readableIdentification.startDate) {
                    localRow.cell.readableIdentification.startDate =  makeValidUtcString(apiRow.cell.readableIdentification.startDate)
                }
                
                if ( localRow.cell && apiRow.cell && localRow.cell.readableIdentification && apiRow.cell.readableIdentification && apiRow.cell.readableIdentification.stopDate) {
                    localRow.cell.readableIdentification.stopDate =  makeValidUtcString(apiRow.cell.readableIdentification.stopDate)
                }
            }

            localRow = await resyncDetailRows(apiRow,localRow)
            if (localRow) {
                await addOrReplaceRecord(fishbookTable, {id: localRow.id, row: localRow}) 
            }
            
        }
    }

    
    return grid
}

async function findlocalRow(apiRow: DynamicGridRow,localRows: DynamicGridRow[]) : Promise<DynamicGridRow | undefined> {
    if ( ! localRows )  return undefined
    if ( ! apiRow) return undefined

    for( const row of localRows) {
        if (apiRow.appSynchronId  && row.appSynchronId && row.appSynchronId.length > 0 && apiRow.appSynchronId === row.appSynchronId) {
            return row
        }
    }

    for( const row of localRows) {
        if (apiRow.rowId  && row.rowId && row.rowId.length > 0 && apiRow.rowId === row.rowId) {
            return row
        }
    }

    for( const row of localRows) {
        if (apiRow.id  && row.id && row.id.length > 0 && apiRow.id === row.id) {
            return row
        }
    }

    return undefined
}

function findlocalMasterRowBy_Revier_Patent_DayDate(apiRow: DynamicGridRow, rows: DynamicGridRow[]) :DynamicGridRow | undefined {
    if (!apiRow.cell || !apiRow.cell.readableIdentification ||  !apiRow.cell.readableIdentification.startDate || apiRow.cell.readableIdentification.startDate.length < 1) {
        return
    }

    for (const row of rows ) {
        if ( !row.cell || !row.cell.readableIdentification) continue
        if ( !row.cell.readableIdentification.readableNumber || row.cell.readableIdentification.readableNumber.length < 1) continue
        if ( !row.cell.readableIdentification.readablePatentNumber || row.cell.readableIdentification.readablePatentNumber.length < 1) continue
        if ( !row.cell.readableIdentification.startDate || row.cell.readableIdentification.startDate.length < 1) continue

        if ( row.cell.readableIdentification?.readableNumber === apiRow.cell.readableIdentification?.readableNumber && row.cell.readableIdentification?.readablePatentNumber === apiRow.cell.readableIdentification?.readablePatentNumber) {
            if ( row.cell.readableIdentification?.startDate && row.cell.readableIdentification?.startDate.length > 0  ) {
                const timestamp = ConvertUtcStringToDate( row.cell.readableIdentification?.startDate )
                const dayDate   = getDateWithoutTime(timestamp)
                const editTimestamp = ConvertUtcStringToDate( apiRow.cell.readableIdentification?.startDate )
                const editDayDate = getDateWithoutTime(editTimestamp)
                if ( dayDate.getTime() === editDayDate.getTime()) {
                    return row
                }
            }
        }
    }
}

async function resyncDetailRows(apiRow: DynamicGridRow | undefined, localRow: DynamicGridRow | undefined) : Promise<DynamicGridRow | undefined> {
    if (!localRow || !apiRow || !localRow.rows || !apiRow.rows) return localRow

    const changedRows: DynamicGridRow[] = []
    const newRows: DynamicGridRow[] = []
    const resultRows: DynamicGridRow[] = []


    for (const apiDetail of apiRow.rows) {
        const localFoundedRow = await findlocalRow(apiDetail, localRow.rows)
        if (!localFoundedRow) {
            newRows.push(apiDetail)
            continue
        } else {
            if( (apiDetail.fangStatistikDigital ?? false) != ( localFoundedRow.fangStatistikDigital ?? false) ) {
                apiDetail.id = localFoundedRow.id
                changedRows.push(apiDetail)
                continue
            }
        }
        
        if( isDifferentAppSynchronId(localFoundedRow.appSynchronId,apiDetail.appSynchronId)) {
            apiDetail.id = localFoundedRow.id
            changedRows.push(apiDetail)
            continue
        } 
    }

    
    for (const localDetail of localRow.rows) {
        const changedRow = await findlocalRow(localDetail, changedRows)
        if (!changedRow) {
            resultRows.push(localDetail)
        }
    }

    

    if (localRow.rows && localRow.rows.length > 0) {
        while(localRow.rows.length > 0) {
            localRow.rows.pop();
        }
    }

    if (!localRow.rows) localRow.rows = []

    for(const row of resultRows) {
        localRow.rows.push(row)
    }

    for(const row of changedRows) {
        localRow.rows.push(row)
    }

    for(const row of newRows) {
        localRow.rows.push(row)
    }
   

    return localRow
}