
import { onDeactivated, onUnmounted, reactive, ref} from "vue";
import DynamicForm from "@/components/Shared/Dynamicform/DynamicForm.vue";
import { RemoteZahlung } from "./RemoteZahlung";
import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
import router from "@/router";
import { BusinessZahlungAusgefuehrt } from "./BusinessZahlungAusgefuehrt";
import { PaymentCancelService } from "@/hooks/PaymentCancelService";
import { Resource } from "@/infrastructure/resource/resource";
import { LightLog, LogTyp } from "@/business/log/LightLog";
import { Out } from "@/infrastructure/frontent/clientMessage";

/* eslint-disable no-undef */
/* tslint:disable */
export default {
  name: "WebShopKasse",
  inheritAttrs: false,
  components: {
    DynamicForm
  },
  setup() {
    const request = reactive({
      apiLoadMethod: "",
      apiSaveMethod: "",
      form: "WebShop",
      page: "Bezahlen",
      remoteId: "",
    });
    const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
    const cancelService = PaymentCancelService.getService();

    const src = ref("")
    let interval: number | undefined = undefined 
    let timer: number | undefined = undefined
    let backGroundTimer: number | undefined = undefined
    let isDisposed = false
    
    const onCancelService = cancelService.subscription().subscribe((value) => {
      if (value && value.IsCanceled) {
        try {
          if (interval) {
            clearInterval(interval)
            interval = undefined
          }
        } 
        catch(e) {
          Out.noOperation("")
        }

        try {
          BusinessZahlungAusgefuehrt.HideWebShopNavbar()
          BusinessZahlungAusgefuehrt.SetState("STOPPED")
          deletePaymentWrapper()
          dispose()
          router.push({ name: 'WebshopFailed'});          
        } 
        catch(e) {
          LightLog.add("WebShopKasse.cancelService" , `Exeception: ${e}`, LogTyp.Fehler)
        }
      

      }
    })

    async function load() {

        isDisposed = false

        try {
          const abbruch = await Resource.getResourceText(`WebShop.Abbruch.Content`)
          BusinessZahlungAusgefuehrt.SetStateText(abbruch)

          const card = await RemoteZahlung.startZahlung()
          if (!card || !card.postFinanceScriptReference || card.postFinanceScriptReference.length < 1 )  {
            return
          }

          src.value = card.postFinanceScriptReference
          addScript(src.value)
          checkAndStartPayment()          
        } 
        catch(e) {
          LightLog.add("WebShopKasse.load" , `Exeception: ${e}`, LogTyp.Fehler)
        }
    }




      async function handlePayment() {
        const result = await BusinessZahlungAusgefuehrt.WebShopZahlungsHandler()
        if(result && result.State &&  result?.State === "SUCCESSFUL" && interval) {
          if( BusinessZahlungAusgefuehrt.isTimeout() ) {

            try {
              if ( interval) {
                clearInterval(interval)
                interval = undefined
              }
            } 
            catch(e) {
              Out.noOperation("")
            }

            successedOnBackgroundCheck()

          }
        } 
        if(result && result.State &&  result?.State === "FAILED" && interval) {
          if( BusinessZahlungAusgefuehrt.isTimeout() ) {

            try {
              if ( interval) {
                clearInterval(interval)
                interval = undefined
              }

            } 
            catch(e) {
              Out.noOperation("")
            }

            failedOnBackgroundCheck()

          }
        } 

        if( result && result.State && ( result?.State === "PROCESSING" || result?.State === "CONFIRMED") && interval) {
          if( ! timer) {
            timer = setTimeout(() => {
              BusinessZahlungAusgefuehrt.ShowWebShopNavbar()
              timer=undefined
            }, 0)
          }
        }

      }


    function failedOnBackgroundCheck() {
      if( ! backGroundTimer) {
        backGroundTimer = setTimeout(() => {
            backGroundTimer = undefined
            try {
              BusinessZahlungAusgefuehrt.HideWebShopNavbar()
              deletePaymentWrapper()
              dispose()
              router.push({ name: 'WebshopFailed'});
            } 
            catch(e) {
              LightLog.add("WebShopKasse.handlePayment.Failed" , `Exeception: ${e}`, LogTyp.Fehler)
            }

        }, 8000)
      }
    }


    function successedOnBackgroundCheck() {
      if( ! backGroundTimer) {
        backGroundTimer = setTimeout(() => {
            backGroundTimer = undefined
            try {
              BusinessZahlungAusgefuehrt.HideWebShopNavbar()
              deletePaymentWrapper()
              dispose()
              router.push({ name: 'WebshopSuccess'});          
            } 
            catch(e) {
              LightLog.add("WebShopKasse.handlePayment.Successful" , `Exeception: ${e}`, LogTyp.Fehler)
            }

        }, 8000)
      }
    }
    
    function deletePaymentWrapper() {
      try {
        const element = document.getElementById("paymentFrameWrapper")
        if (element) element.remove()
      } 
      catch(e) {
        LightLog.add("userProfile.deletePaymentWrapper" , `Exeception: ${e}`, LogTyp.Fehler)        
      }
    }

    function addScript(scriptReference: string) {
      try {
        const element = document.getElementById("PostFinance");
        if(element)
          element.remove()

        const postFinanceScript = document.createElement('script');
        postFinanceScript.setAttribute(
          'id',
          'PostFinance'
        );
        postFinanceScript.setAttribute(
          'src',
          scriptReference
        );
        postFinanceScript.setAttribute('async', 'true');
        postFinanceScript.setAttribute('defer', 'true');
        document.head.appendChild(postFinanceScript);          
      } 
      catch(e) {
        LightLog.add("WebShopKasse.addScript" , `Exeception: ${e}`, LogTyp.Fehler)
      }
    }

    function checkAndStartPayment()  {
      let i = 0;
      
      const paymentStartInterval = setInterval(() =>  {
          if (i == 20 || (checkForPaymentUrl() && i > 1) ) {

            try {
              clearInterval(paymentStartInterval)            
              startPayment()        
            } 
            catch(e) {
              LightLog.add("WebShopKasse.checkAndStartPayment" , `Exeception: ${e}`, LogTyp.Fehler)
            }
  
          }
          i++
      }, 500 )


      if ( !interval) interval = setInterval(() => {
        handlePayment()        
      }, 10000)

    }

    function checkForPaymentUrl() : boolean {
      try {
        const element = document.getElementById("PostFinance")
        if (element) {
          const temp = element as HTMLScriptElement
          const htmlSrc = temp.src
            if (htmlSrc === src.value ) return true
        }          
      } 
      catch(e) {
        LightLog.add("WebShopKasse.checkForPaymentUrl" , `Exeception: ${e}`, LogTyp.Fehler)
      }
      return false
    }
  
    function resetLoaderState() {
      setTimeout(()=> {
        try {
          loaderState.changeLoaderState({isInProgress: false})          
        } 
        catch(e) {
          LightLog.add("WebShopKasse.resetLoaderState" , `Exeception: ${e}`, LogTyp.Fehler)        
        }
      }, 1000)
      
    }

    function startPayment() {
      
      var paymentMethodConfigurationId = 1;
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      if (window.LightboxCheckoutHandler) {
        resetLoaderState()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.LightboxCheckoutHandler.startPayment(paymentMethodConfigurationId, function () {
            alert('An error occurred during the initialization of the payment lightbox.');
        });
      }
    }

    function dispose() {

      if (isDisposed) {
        return
      }
      isDisposed = true

      try {
        onCancelService.unsubscribe()
      } catch(e) {
        Out.noOperation("")
      }
      try { 
        if( interval ) {
          clearInterval(interval)
          interval = undefined
        }
      } catch(e) {
        console.log(e)
      }
      try { 
        if( timer ) {          
          clearTimeout(timer)
          timer = undefined
        }
      } catch(e) {
        console.log(e)
      }
      try { 
        if( backGroundTimer ) {          
          clearTimeout(backGroundTimer)
          backGroundTimer = undefined
        }
      } catch(e) {
        console.log(e)
      }

    }

    load()

    onUnmounted(() => {
      dispose()        
      });

    onDeactivated(() => {
      dispose()
    });

    return {
      request,
      src,
      startPayment
    };
  }
};
