import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34217962"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid g-1" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col m-12" }
const _hoisted_4 = { class: "row g-0 efj__headline--small" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = {
  key: 1,
  class: "row"
}
const _hoisted_7 = { class: "col m-12" }
const _hoisted_8 = { class: "row g-0 efj__headline--small" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = {
  key: 2,
  class: "web-shop-canceledState"
}
const _hoisted_11 = { class: "row line-space" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 3,
  class: "web-shop-state"
}
const _hoisted_15 = { class: "row line-space" }
const _hoisted_16 = { class: "col-6" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = {
  key: 4,
  class: "web-shop-state"
}
const _hoisted_19 = { class: "row line-space" }
const _hoisted_20 = { class: "col-6" }
const _hoisted_21 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.backGroundZahlung.state !== 'STOPPED')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createElementVNode("p", null, _toDisplayString($setup.request.title), 1)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.backGroundZahlung.state === 'STOPPED')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", null, [
                  _createElementVNode("p", null, _toDisplayString($setup.backGroundZahlung.verzoegertTitle), 1)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!$setup.offlineKasse.value && $setup.backGroundZahlung.state === 'STOPPED')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", {
                innerHTML: $setup.backGroundZahlung.verzoegert
              }, null, 8, _hoisted_13)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!$setup.offlineKasse.value && $setup.backGroundZahlung.state !== 'STOPPED')
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("p", null, _toDisplayString($setup.request.betrag), 1)
            ]),
            _createElementVNode("div", _hoisted_17, _toDisplayString($setup.cardResult.shoppingCard.amountIncludeTax + " CHF"), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.offlineKasse.value && $setup.backGroundZahlung.state !== 'STOPPED')
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("p", null, _toDisplayString($setup.request.offline), 1)
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("p", null, _toDisplayString($setup.request.ueberpruefen), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}