import { ConvertUtcStringToDate, getCurrentDayDate, getDateWithoutTime, isSameDay, newDate, overwriteDateValue } from '@/infrastructure/functional/datetimehelper';
import { ObjectListItems } from '../../../infrastructure/generics/namedListItem';
import { GetEndOfDayDateTime } from "@/infrastructure/functional/datetimehelper"
import { DynamicGridRequest } from '@/components/Shared/dynamicList/DynamicListTypes';
import { addOrUpdateLocalFishingParentRecord, stopLocalFishingParentRecord } from './FishingAdd';
import { Identity } from '@/infrastructure/backend/userIdentity';
import { UserProfile } from '@/infrastructure/backend/userProfile';
import { addOrReplaceRecord, fischFangStartStopTable, getRecord } from '@/infrastructure/database/asyncDb';
import { Out } from '@/infrastructure/frontent/clientMessage';
import { RefreshServerObjects } from '@/infrastructure/resource/RefreshServerObjects';

export type FishingEditState = {
    startDate: Date | undefined,
    stoppDate: Date | undefined,
    patent: ObjectListItems | undefined,
    fischereiRevier: ObjectListItems | undefined   
    isStarted:boolean
}

export class FishingEdit {

    private static fishingEditState: FishingEditState = {
        startDate: undefined,
        stoppDate: undefined,
        patent: undefined,
        fischereiRevier: undefined,
        isStarted: false
    }

    private static blocked = false
    
    public static async loadFishingEditSate() {
        try {
            const result =  await getRecord(fischFangStartStopTable, "1")
            if ( result ) {
                if ( result.startDate ) {
                    result.startDate  = ConvertUtcStringToDate(result.startDate)
                }
                if ( result.stoppDate ) {
                    result.stoppDate  = ConvertUtcStringToDate(result.stoppDate)
                }
                FishingEdit.fishingEditState = result as FishingEditState
                
                
            }
        } catch(e) {
            Out.noOperation("loadFishingEditSate");
        }
    }

    private static async saveFishingEditState() {
        const record = JSON.parse(JSON.stringify( FishingEdit.fishingEditState ))
        if ( FishingEdit.fishingEditState.startDate ) { 
            record.startDate = overwriteDateValue( FishingEdit.fishingEditState.startDate )
        }
        if ( FishingEdit.fishingEditState.stoppDate ) { 
            record.stopDate = overwriteDateValue( FishingEdit.fishingEditState.stoppDate )
        }
        
        const data = {
            id: "1",        
            ...record
        }

        await addOrReplaceRecord(fischFangStartStopTable, data)
    }

    private static async saveFishingStopEditState() {
        const record = JSON.parse(JSON.stringify( FishingEdit.fishingEditState ))
        if ( FishingEdit.fishingEditState.startDate ) { 
            record.startDate = overwriteDateValue( FishingEdit.fishingEditState.startDate )
        }

        record.isStarted = false
               
        const data = {
            id: "1",        
            ...record
        }

        await addOrReplaceRecord(fischFangStartStopTable, data)

        record.startDate = undefined
        

    }

    public static async startFishing(patent: ObjectListItems | undefined ,fischereiRevier: ObjectListItems | undefined) : Promise<boolean> {
        if ( patent === undefined || fischereiRevier === undefined)
            return false
        if ( !patent.key || !fischereiRevier.key || patent.key.length < 1 || fischereiRevier.key.length < 1  )
            return false

            FishingEdit.fishingEditState.startDate = newDate()
            FishingEdit.fishingEditState.stoppDate = GetEndOfDayDateTime(FishingEdit.fishingEditState.startDate)

            FishingEdit.fishingEditState.patent = patent
            FishingEdit.fishingEditState.fischereiRevier = fischereiRevier
            FishingEdit.fishingEditState.isStarted = true

            const request: DynamicGridRequest = {
                userId: Identity.getIdentity().getValue().UserId,
                viewId: "fishBookEntriesWithDetail",
                activeData: true,
                pageSize: 1,
                revierNr: "",
                routeName: "",
                detailRouteName: "",
                page: "1",
                language: (await UserProfile.GetClientSettingsAsync()).language,
                detailId: '',
                detailList: false,
                allRecords: true,
                lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                lastViewIdRefreshState: await RefreshServerObjects.getLastGridFishBookRefreshDate()
            }
            await addOrUpdateLocalFishingParentRecord(request.viewId)

            await FishingEdit.saveFishingEditState()

        return true
    }

    public static async isFishingOnSameDay() : Promise<boolean> {
        if ( FishingEdit.fishingEditState.isStarted && FishingEdit.fishingEditState.startDate) {
            const dt = getCurrentDayDate()
            const dtStart = getDateWithoutTime(FishingEdit.fishingEditState.startDate)
            if (dt.getTime() === dtStart.getTime()) return true
            return false
        }
        return true
    }

    public static hasValidProductOnCurrentDay() {
        return true;
    }

    public static async stoppFishing() : Promise<boolean> {
        if ( FishingEdit.fishingEditState.startDate === undefined ) return false            
        FishingEdit.fishingEditState.stoppDate = newDate()
        FishingEdit.fishingEditState.isStarted = false
        await stopLocalFishingParentRecord("fishBookEntriesWithDetail")
        await FishingEdit.saveFishingStopEditState()
        return true
    }

    public static async reset() {
        FishingEdit.fishingEditState.startDate = undefined
        FishingEdit.fishingEditState.stoppDate = undefined

        FishingEdit.fishingEditState.patent = undefined
        FishingEdit.fishingEditState.fischereiRevier = undefined
        FishingEdit.fishingEditState.isStarted = false
        await FishingEdit.saveFishingEditState()
    }

    public static getEditFishingStateInfo(reloadFromDb: boolean) : FishingEditState { 
        return FishingEdit.fishingEditState;
    }

    public static async getEditFishingStateAsync() : Promise<FishingEditState> {
        await this.handleEditFishingState()
        return FishingEdit.fishingEditState;
    }

   

    private static async handleEditFishingState() : Promise<boolean> {
        if ( FishingEdit.fishingEditState.startDate === undefined) {
            FishingEdit.fishingEditState.stoppDate = undefined
            FishingEdit.fishingEditState.isStarted = false
            await FishingEdit.saveFishingEditState()
            return false
        }
       
        const currentDate = getCurrentDayDate()
        
        if ( isSameDay(currentDate, FishingEdit.fishingEditState.startDate) && getDateWithoutTime(FishingEdit.fishingEditState.startDate).getTime() <= currentDate.getTime() ) {           
            //FishingEdit.fishingEditState.startDate = newDate()
            FishingEdit.fishingEditState.stoppDate = GetEndOfDayDateTime(FishingEdit.fishingEditState.startDate)
            await FishingEdit.saveFishingEditState()
            return true
        }


       
        // if ( !isSameDay(currentDate, FishingEdit.fishingEditState.startDate) && getDateWithoutTime(FishingEdit.fishingEditState.startDate).getTime() <= currentDate.getTime() ) {                              
        //     await FishingEdit.handleFishingStateStopWheNextDay()
        //     await FishingEdit.handleFishingStateStartWheNextDay()
        //     return true
        // }

        return false

    }


    private static async handleFishingStateStartWheNextDay() {
           
            if ( FishingEdit.fishingEditState.startDate ) return

            FishingEdit.fishingEditState.startDate = newDate()
            FishingEdit.fishingEditState.stoppDate = GetEndOfDayDateTime(FishingEdit.fishingEditState.startDate)
    
            FishingEdit.fishingEditState.isStarted = true
            let record = JSON.parse(JSON.stringify( FishingEdit.fishingEditState ))

            let data = {
                id: "1",        
                ...record
            }
    
            const request: DynamicGridRequest = {
                userId: Identity.getIdentity().getValue().UserId,
                viewId: "fishBookEntriesWithDetail",
                activeData: true,
                pageSize: 1,
                revierNr: "",
                routeName: "",
                detailRouteName: "",
                page: "1",
                language: (await UserProfile.GetClientSettingsAsync()).language,
                detailId: '',
                detailList: false,
                allRecords: true,
                lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                lastViewIdRefreshState: await RefreshServerObjects.getLastGridFishBookRefreshDate()
            }
            await addOrUpdateLocalFishingParentRecord(request.viewId)
    
    
    
            record = JSON.parse(JSON.stringify( FishingEdit.fishingEditState ))
            if ( FishingEdit.fishingEditState.startDate ) { 
                record.startDate = overwriteDateValue( FishingEdit.fishingEditState.startDate )
            }
            if ( FishingEdit.fishingEditState.stoppDate ) { 
                record.stopDate = overwriteDateValue( FishingEdit.fishingEditState.stoppDate )
            }
            
            data = {
                id: "1",        
                ...record
            }
    
            await addOrReplaceRecord(fischFangStartStopTable, data)

           
    

    }


     // __ Neue Logik für den nächsten Tag
    private static async handleFishingStateStopWheNextDay() {

        
        try {

            // Fischen beenden
            if ( !FishingEdit.fishingEditState.startDate) return
            FishingEdit.fishingEditState.isStarted = false                        
            FishingEdit.fishingEditState.stoppDate = GetEndOfDayDateTime(FishingEdit.fishingEditState.startDate)  
            await stopLocalFishingParentRecord("fishBookEntriesWithDetail")  
    
            const record = JSON.parse(JSON.stringify( FishingEdit.fishingEditState ))
            if ( FishingEdit.fishingEditState.startDate ) { 
                record.startDate = overwriteDateValue( FishingEdit.fishingEditState.startDate )
            }
            if ( FishingEdit.fishingEditState.stoppDate ) { 
                record.stopDate = overwriteDateValue( FishingEdit.fishingEditState.stoppDate )
            }
            
            const data = {
                id: "1",        
                ...record
            }
    
            await addOrReplaceRecord(fischFangStartStopTable, data)

            FishingEdit.fishingEditState.startDate = undefined            
        }
        catch(e) {
            Out.noOperation("handleFishingStateStopWheNextDay");
        }

    }


   
}