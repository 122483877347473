import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d3a5893"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row home__content" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row g-0 efj__headline" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row g-0" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "row align-text-bottom" }
const _hoisted_9 = { class: "col m-12" }
const _hoisted_10 = {
  key: 0,
  class: "row"
}
const _hoisted_11 = { class: "col m-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, _toDisplayString($setup.componentResource.efjTitel), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              innerHTML: $setup.componentResource.nachrichten,
              class: "efj__multiline"
            }, null, 8, _hoisted_7)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($setup.fishing && $setup.fishing(...args)), ["prevent"])),
          class: "btn action__button_primary efj__contentnavigationbutton--special"
        }, _toDisplayString($setup.componentResource.fischen), 1)
      ])
    ]),
    ($setup.componentResource.jagenEnabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($setup.hunting && $setup.hunting(...args)), ["prevent"])),
              class: "btn action__button_primary efj__contentnavigationbutton--special"
            }, _toDisplayString($setup.componentResource.jagd), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}