import { StateConstants } from '@/business/application/statemachine/constants/StateConstants';
import { LightLog, LogTyp } from '@/business/log/LightLog';
import { personProfileTable, addOrReplaceRecord, getRecord, ClientSettingsTable} from "@/infrastructure/database/asyncDb"
import { AsyncRestClient } from './async/AsyncRestClient';
import { triggerRef } from 'vue';
import { Identity } from './userIdentity';
import { getBrowserLanguage } from '../frontent/LanguageBrowserDetection';
import { newDate, overwriteDateValue } from '../functional/datetimehelper';

export interface IUserProfile {
    first: boolean,
    userId: string,
    roles: string,
    lastLogin: Date,
    online: boolean,    
    lastMainViewMainState: string    
    hasUnsyncedData: boolean
    expired: boolean
}

export interface IClientSettings {
    language: string
}

export type SettingResult = {
    language: string
}


export class UserProfile {
    
    private static ClientSettings: IClientSettings = {
        language: "de",
    }

    public static GetClientSettingsSync() : IClientSettings {
        return UserProfile.ClientSettings
    }

    public static async  GetClientSettingsAsync() : Promise<IClientSettings> {
        let result: IClientSettings = {
            language: "de"
        }
        try {
          
            const temp =  await getRecord(ClientSettingsTable, "1")
            if ( temp && temp.row) {
                result  = temp.row as IClientSettings
            } else {
                const temp = getBrowserLanguage()
                result.language = temp
                await UserProfile.AddOrUpdateClientSettings(result)

            }
            UserProfile.ClientSettings.language = result.language
            return result    
        } catch (e) {
            LightLog.add("userProfile.GetClientSettings" , `Exeception: ${e}`, LogTyp.Fehler)
        }
        return result
    }

    public static CurrentProfile: IUserProfile = {
        first: true,
        userId: "",
        roles: "",
        lastLogin: new Date("2000-01-01"),
        online: true,
        lastMainViewMainState: StateConstants.Login,
        hasUnsyncedData: false,
        expired: false
    }

    private IntializeProfileRecord() : IUserProfile
    {
        const profile: IUserProfile = {
            first: true,
            userId: "",
            roles: "",
            lastLogin: new Date("2000-01-01"),
            online: true,
            lastMainViewMainState: StateConstants.Login,
            hasUnsyncedData: false,
            expired: false
          }
          return profile;
    }

    private static IntializeProfileRecordStatic() : IUserProfile
    {
        const profile: IUserProfile = {
            first: true,
            userId: "",
            roles: "",
            lastLogin: new Date("2000-01-01"),
            online: true,
            lastMainViewMainState: StateConstants.Login,
            hasUnsyncedData: false,
            expired: false
          }
          return profile;
    }

    private async AddOrUpdateProfileData(jsonProfileData: string) : Promise<void> {
        const profile = JSON.parse(jsonProfileData) as IUserProfile
        const record = JSON.parse(JSON.stringify(profile))
        record.lastLogin = overwriteDateValue( record.lastLogin)
        
        await addOrReplaceRecord(personProfileTable, {"id": "1", "row" : record})
    }

    public async GetProfileData() : Promise<IUserProfile> {
        try {

            await this.GetClientSettings()

            const result =  await getRecord(personProfileTable, "1")
            if ( !result || !result.row) {
                return this.IntializeProfileRecord()
            }
            UserProfile.CurrentProfile = (result.row as IUserProfile)
            return result.row as IUserProfile    
        } catch (e) {
            LightLog.add("userProfile.GetProfileData" , `Exeception: ${e}`, LogTyp.Fehler)
        }
        return this.IntializeProfileRecord()
    }

    public static async GetCurrentProfile() : Promise<IUserProfile> {
        try {
            await UserProfile.GetClientSettingsAsync()

            const result =  await getRecord(personProfileTable, "1")
            if ( !result || !result.row) {
                return this.IntializeProfileRecordStatic()
            }

            UserProfile.CurrentProfile = (result.row as IUserProfile)
            return result.row as IUserProfile    
        } catch (e) {
            LightLog.add("userProfile.GetProfileData" , `Exeception: ${e}`, LogTyp.Fehler)
        }


        LightLog.add("IsLoggedOn" , `Return Unitialized Profile`, LogTyp.Info)

        return this.IntializeProfileRecordStatic()
    }

    public async IsLoggedOn() : Promise<boolean> {
        try {


            if ( !Identity.isUserRole( )) return false
            LightLog.add("IsLoggedOn" , `User in UserRole`, LogTyp.Info)


            await UserProfile.GetClientSettingsAsync()           
            LightLog.add("IsLoggedOn" , `GetUserProfile`, LogTyp.Info)


            const result =  await getRecord(personProfileTable, "1")
            if ( !result || !result.row ) {
                return false
            }

            LightLog.add("IsLoggedOn" , `Profile Data-Row-Loaded`, LogTyp.Info)

            UserProfile.CurrentProfile = (result.row as IUserProfile)
            const temp = result.row as IUserProfile   
            if( ! temp || temp.expired) {
                return false
            }
            
            LightLog.add("IsLoggedOn" , `Not expired`, LogTyp.Info)

            
            // V2-24
            // if (temp.roles && temp.roles.indexOf("User") >= 0 && !temp.hasUnsyncedData ) {
            //     return true
            // }

            if (temp.roles && temp.roles.indexOf("User") >= 0 ) {
                LightLog.add("IsLoggedOn" , `User Role found`, LogTyp.Info)
                return true
            }


            return false
        } catch (e) {
            LightLog.add("userProfile.GetProfileData" , `Exeception: ${e}`, LogTyp.Fehler)
        }
        return false
    }

    public async appUserEqualCookieUser(cookieUserId: string) {
        if ( ! cookieUserId || cookieUserId.length < 1) return false

        const profile = await this.GetProfileData()
        if (! profile ) return true

        if( profile.userId === cookieUserId) return true

        return false

    }

    public async hasUnsyncedData() : Promise<boolean> {
        const record = await this.GetProfileData()
        if(record) {
            if ( record.hasUnsyncedData ) return true
        }
        return false
    }

    public async setUnsyncedData() {
        const record = await this.GetProfileData()
        record.hasUnsyncedData = true
        record.lastMainViewMainState = StateConstants.Login
        await this.AddOrUpdateProfileData(JSON.stringify(record))
    }

    public async resetUnsyncedData() {
        const record = await this.GetProfileData()
        record.hasUnsyncedData = false
        record.first = true
        record.lastMainViewMainState = StateConstants.Login    
        await this.AddOrUpdateProfileData(JSON.stringify(record))
    }

    public async resetProfile(caller: string) {
        const record = await this.GetProfileData()
        record.hasUnsyncedData = false
        record.userId = ""
        record.roles = ""
        record.lastMainViewMainState = StateConstants.Login
        record.lastLogin = new Date("2000-01-01")
        
        await this.AddOrUpdateProfileData(JSON.stringify(record))
    }

    public async resetExpired() {
        const record = await this.GetProfileData()
        record.expired = false;
        await this.AddOrUpdateProfileData(JSON.stringify(record))
    }

    public async setExpired() {
        const record = await this.GetProfileData()
        record.expired = true;
        await this.AddOrUpdateProfileData(JSON.stringify(record))
    }

    public async ChangeLastMainState( lastMainState: string) {        
        const record = await this.GetProfileData()
        record.lastMainViewMainState = lastMainState
        await this.AddOrUpdateProfileData(JSON.stringify(record))
    }


    public async ChangeUserId( userId: string) {        
        const record = await this.GetProfileData()
        record.userId = userId
        await this.AddOrUpdateProfileData(JSON.stringify(record))
    }

    public async ChangeRoles( roles: string) {        
        const record = await this.GetProfileData()
        record.roles = roles
        await this.AddOrUpdateProfileData(JSON.stringify(record))
    }

    public async ChangeOnline( online: boolean) {        
        const record = await this.GetProfileData()
        record.online = online
        await this.AddOrUpdateProfileData(JSON.stringify(record))
        await this.GetProfileData()
    }


    public async ChangeLastLoginDate() : Promise<void> {
        const record = await this.GetProfileData()
        record.lastLogin = newDate()
        await this.AddOrUpdateProfileData(JSON.stringify(record))
    }

    public  async ChangeLanguage(language: string) : Promise<void> {
        const record = await this.GetClientSettings()
        record.language = language
        await UserProfile.AddOrUpdateClientSettings(record)
    }


    private async GetClientSettings() : Promise<IClientSettings> {
        let result: IClientSettings = {
            language: "de"
        }
        try {
          
            const temp =  await getRecord(ClientSettingsTable, "1")
            if ( temp && temp.row) {
                result  = temp.row as IClientSettings
            }
            UserProfile.ClientSettings.language = result.language
            return result   
        } catch (e) {
            LightLog.add("userProfile.GetClientSettings" , `Exeception: ${e}`, LogTyp.Fehler)
        }
        return result
    }

    private static async AddOrUpdateClientSettings(clientSettings: IClientSettings) : Promise<void> {
        const record = JSON.parse(JSON.stringify(clientSettings))
        await addOrReplaceRecord(ClientSettingsTable, {"id": "1", "row" : record})
    }

    

}