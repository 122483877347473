import { DynamicGridRow } from "@/components/Shared/dynamicList/DynamicListTypes"
import { fishbookTable, loadAllRecords, wildbookTable } from "../database/asyncDb"

export async function isAllSynced() : Promise<boolean> {

    try {
        const dataRows = await loadAllRecords(fishbookTable) as any
        if ( dataRows === undefined ) return true
        for(const item of dataRows) {
            const parentRow = item.row as DynamicGridRow 
            if (! parentRow?.isSynced) {
                return false
            }
        }
    
        
    } catch(e) {
        return false
    }

    try {
        const dataRows = await loadAllRecords(wildbookTable) as any
        if ( dataRows === undefined ) return true
        for(const item of dataRows) {
            const parentRow = item.row as DynamicGridRow 

            let isSynced: boolean | undefined = false 
            isSynced = parentRow.isSynced ?? true
    
            if (! isSynced) {
                return false
            }
        }
    
        return true    
    } catch(e) {
        return false
    }

}