import { Out } from '@/infrastructure/frontent/clientMessage';
import { DynamicGrid, DynamicGridRequest, DynamicGridRow } from "@/components/Shared/dynamicList/DynamicListTypes"
import { ConvertCanonicalToDate, ConvertCanonicalToDayDate, makeValidUtcString, overwriteSerializedLocalGridDate } from "../functional/datetimehelper"
import { Result } from "../functional/result"
import { Identity } from "../backend/userIdentity"
import { UserProfile } from "../backend/userProfile"
import { WildbuchTyp, addOrReplaceRecord, getRecord, loadAllRecords, personProductsTable, wildbookTable } from "../database/asyncDb"
import { getFishBookPersonProducts } from "./DynamicGridServiceWorker"
import { AsyncRestClient } from "../backend/async/AsyncRestClient"
import { RefreshServerObjects } from '../resource/RefreshServerObjects';
import { Navigate } from '../backend/async/Navigate';
import { DynamicRecord } from '@/components/Shared/Dynamicform/DynamicFormData';
import { ComboBoxDataSource } from '@/components/Shared/Dynamicform/ComboBoxDataSource';


export async function getDynamicWildBookGridFromApi(request: DynamicGridRequest, allRecords: boolean) : Promise<DynamicGrid | undefined> {
    let gridObject : DynamicGrid = {userId: request.userId , viewId: request.viewId}
    request.lastRefreshDate = await RefreshServerObjects.getLastGridFormRefreshDate()
    
    if (request.viewId === "personProductsWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridProductsRefreshDate()
    }
    if (request.viewId === "wildBookEntriesWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridWildBookRefreshDate()
    }
    if (request.viewId === "fishBookEntriesWithDetail") {
        request.lastViewIdRefreshState = await RefreshServerObjects.getLastGridFishBookRefreshDate()
    }
    if( Navigate.IsLocked() ) {
        return undefined
    }

    const client = AsyncRestClient.Create(true);
    if (client.isFailure) throw new Error("Error.getDynamicGridData");
    const result = await client
        .getValue()
        .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/grid/loadDynamicGridData", JSON.stringify(request));  

    if (result.isFailure) throw new Error(`${result.error}`)            
    gridObject = JSON.parse(JSON.stringify(result.getValue())) as unknown as DynamicGrid 
    overwriteSerializedLocalGridDate(result.getValue() as unknown as DynamicGrid , gridObject)


    if ( gridObject.viewId === "personProductsWithDetail" && gridObject.rows) {
        if ( (gridObject.isModified ?? true)) {
            for (const row of gridObject.rows) {
                if ( row.cell.readableIdentification?.startDate && row.cell.readableIdentification?.startDate.length > 0 ) {
                    row.cell.readableIdentification.startDate = makeValidUtcString(row.cell.readableIdentification?.startDate)
                }    
                if ( row.cell.readableIdentification?.stopDate && row.cell.readableIdentification?.stopDate.length > 0 ) {
                    row.cell.readableIdentification.stopDate = makeValidUtcString(row.cell.readableIdentification?.stopDate)
                }          
                await addRecordsToDatabaseWhenPossible(row, personProductsTable)
            }
            if(gridObject && gridObject.rows && gridObject.rows.length > 0)
                await RefreshServerObjects.addLastGridProductsRefreshDate( gridObject.lastRefreshDate ?? RefreshServerObjects.getCurrentLastRefreshDate())

        }
        const tempGridObject = await getFishBookPersonProducts(request.viewId, allRecords, request)
        if ( ! tempGridObject) return
        if( tempGridObject.getValue() === undefined) return
        gridObject = tempGridObject.getValue()
    }

    if ( gridObject.viewId === "wildBookEntriesWithDetail" && gridObject.rows) {
        for (const row of gridObject.rows) {      
            if ( row.cell.readableIdentification?.startDate && row.cell.readableIdentification?.startDate.length > 0 ) {
                row.cell.readableIdentification.startDate = makeValidUtcString(row.cell.readableIdentification?.startDate)
            }                  
        }
        const tempGridObject = await getWildBookEntriesWithDetail(request.viewId, allRecords, request)
        if ( ! tempGridObject) return
        if( tempGridObject.getValue() === undefined) return
        gridObject = tempGridObject.getValue()

        if(gridObject && gridObject.rows && gridObject.rows.length > 0)
            await RefreshServerObjects.addLastGridWildBookRefreshDate(RefreshServerObjects.getCurrentLastRefreshDate())

    }

    return gridObject
}

export async function getWildBookEntriesWithDetail( viewId: string, allRows: boolean, request?: DynamicGridRequest ) : Promise<Result<DynamicGrid> | undefined> {
    const dataRows = await loadAllRecords(wildbookTable)
    if ( dataRows === undefined ) return undefined

    const rows: DynamicGridRow[] = []
    for ( const item of dataRows as any ) {
        


        if ( ! request) {
            rows.push( item.row )
            continue
        }

        if (! isWildBookFilter(request)) {
            rows.push( item.row )
            continue
        } 

        if(await wildBookFilter(request,item.row)) {
            rows.push(item.row)
        }

        
        // if (item.detailForm && item.detailForm.view && item.detailForm.view.length > 0) {
        //     for( const formItem  of item.detailForm.view as DynamicRecord[]) {

        //         if ( formItem.formularFeldname.toLowerCase() === "Datum" ) {
        //             item.row.cell.readableIdentification.order = (formItem.value ?? "")
        //         }

        //     }
        // } else {
        //     item.row.cell.readableIdentification.order = (item.row.cell.readableIdentification?.startDate ?? "")
        // }

    }

    if ( ! allRows) {

        // rows.sort(function(a: DynamicGridRow,b: DynamicGridRow){
        //     return (b.cell.readableIdentification!.startDate ?? "").localeCompare(a.cell.readableIdentification!.startDate ?? "");


        for ( const item of rows as any ) {
            try {

                if (item.detailForm && item.detailForm.view && item.detailForm.view.length > 0) {
                    for( const formItem  of item.detailForm.view as DynamicRecord[]) {
        
                        if ( formItem.formularFeldname.toLowerCase() === "datum" ) {
                            if ( ! formItem.value || formItem.value.length < 1) continue

                            if ( formItem.value && formItem.value.length > 10) {
                                item.cell.readableIdentification.order =  (formItem.value ?? "").substring(0,10)
                                item.cell.additional.text = item.cell.readableIdentification.order
                            } else {
                                item.cell.readableIdentification.order = (formItem.value ?? "")
                                item.cell.additional.text = item.cell.readableIdentification.order
                            }

                        }

                        if ( formItem.formularFeldname.toLowerCase() === "zeit" ) {
                            if ( ! formItem.value || formItem.value.length < 1) continue

                            if ( formItem.value && formItem.value.length < 10) {
                                item.cell.readableIdentification.order = (item.cell.readableIdentification.order ?? "") + " " + formItem.value
                                item.cell.additional.text = item.cell.additonal.text + "<br />" + formItem.value
                            } else {
                            const result = await ComboBoxDataSource.GetValue("WildbuchZeit", formItem.value)
                            if ( result) {
                                item.cell.readableIdentification.order = (item.cell.readableIdentification.order ?? "") + " " + result
                                item.cell.additional.text = item.cell.additional.text + "<br />" + result
                            }
                        }
                            
                        }
        
                    }
                } else {
                    item.row.cell.readableIdentification.order = (item.row.cell.readableIdentification?.startDate ?? "")
                }
            }
            catch (e) {
                Out.noOperation(e)
            }
        }

        rows.sort(function(a: DynamicGridRow,b: DynamicGridRow){
            return (b.cell.readableIdentification!.order ?? "").localeCompare(a.cell.readableIdentification!.order ?? "");
    

      });     
    }    

    const grid: DynamicGrid = {
        userId: Identity.getIdentity().getValue().UserId,
        viewId: viewId,
        pages: 1,
        pageSize: 6,
        rows: rows as unknown as DynamicGridRow[],
        language: (await UserProfile.GetClientSettingsAsync()).language,
        isOffline: true     
    }   
    return Result.ok<DynamicGrid>(grid)
}

function isWildBookFilter(request: DynamicGridRequest) :boolean {
    if( request?.dynamicFilterItems ) {
        for( const filterItem of request.dynamicFilterItems ) {
            if( filterItem.value && filterItem.value !== "") {
                return true
            }
        }
    }
    return false
}

async function wildBookFilter(request: DynamicGridRequest, parentRow: DynamicGridRow) :Promise<boolean> {
    const wildbuchTyp = await loadAllRecords(WildbuchTyp) as any
    const wildbuchRows = wildbuchTyp[0].objectList 
    
    if( request?.dynamicFilterItems ) {
        for( const filterItem of request.dynamicFilterItems ) {
            if( !filterItem.value || filterItem.value === "") {
                continue
            }
            
            if ( filterItem.formularFeldname.indexOf("WildbuchTyp") >= 0 ) { 
                let found = false;
                let filterValue: string | undefined = undefined
                if ( filterItem.value && filterItem.value.length > 0) {
                    for(const r of wildbuchRows) {
                        if (r.key && r.key === filterItem.value) {
                            filterValue = r.resourceId
                        }
                    }
                    if (! filterValue || filterValue.length < 1) return false
                    filterValue = filterValue.replace("WildbuchTyp.", "")
                    if (filterValue === "Beobachtung") {
                        if(parentRow.detailForm && parentRow.detailForm.viewId.indexOf(filterValue) >=0  && parentRow.detailForm && parentRow.detailForm.viewId.indexOf("Spez") < 0 ) {
                            found = true
                        }
                    } else {
                        if (filterValue === "BeobachtungSpezial") filterValue = "BeobachtungSpez"

                        if(parentRow.detailForm && parentRow.detailForm.viewId.indexOf(filterValue) >=0 ) {
                            found = true
                        }
                    }
                    if (!found) return false
                }
            }
            
          

            if ( parentRow ) {
                
                if( ! parentRow.detailForm || ! parentRow.detailForm.view || parentRow.detailForm.view.length === 0 ) {
                    continue
                }

                for (const item of parentRow.detailForm?.view) {
                    if ( item.formularFeldname.toLowerCase() === filterItem.formularFeldname.toLocaleLowerCase() 
                        || (item.formularFeldname.toLowerCase() === "datum" && filterItem.formularFeldname.toLocaleLowerCase() === "datumvon")
                        || (item.formularFeldname.toLowerCase() === "datum" && filterItem.formularFeldname.toLocaleLowerCase() === "datumbis")
                        || (item.formularFeldname && item.formularFeldname.toLowerCase().indexOf("tierart") >=0 && filterItem.formularFeldname.toLocaleLowerCase() === "tierart")
                        || (item.formularFeldname && item.formularFeldname.toLowerCase().indexOf("jagdrevier") >=0 && filterItem.formularFeldname.toLocaleLowerCase() === "jagdrevier")
                    ) {
                        if ( !item.value || item.value === "" ) return false

                        if ( filterItem.formularFeldname.toLocaleLowerCase() == "jagdrevier") {
                            if (filterItem && filterItem.value) {
                                if (typeof(filterItem.value) === typeof("string")) {
                                    if (filterItem.value.length > 0 && filterItem.value !== item.value) {
                                        return false
                                    }
                                } else {
                                    if(filterItem.namedValueList && filterItem.namedValueList.length > 0 && filterItem.value.key && filterItem.value.key.length > 0) {
                                        for(const listItem  of filterItem.namedValueList) {
                                            if ( listItem.key === filterItem.value.key) {
                                                if(listItem.key !== item.value) {
                                                    return false
                                                }
                                            }
                                        }
                                    }
                                }
                            }

     
                        }
                        
                        if ( filterItem.formularFeldname.indexOf( "Tierart" ) >= 0 ) {
                            if( filterItem.value !== item.value) return false
                        }
                      
                        if ( filterItem.formularFeldname == "Geschlecht" ) {
                            if( filterItem.value !== item.value) return false
                        }
                        if ( filterItem.formularFeldname == "DatumVon" ) {
                            const filterValue = ConvertCanonicalToDayDate(filterItem.value, false)
                            const itemValue = ConvertCanonicalToDayDate(item.value, false)
                            if ( !filterValue || !itemValue ) return false
                            if ( itemValue.getTime() < filterValue.getTime() ) return false
                        }
                        if ( filterItem.formularFeldname == "DatumBis" ) {
                            const filterValue = ConvertCanonicalToDayDate(filterItem.value, false)
                            const itemValue = ConvertCanonicalToDayDate(item.value, false)
                            if ( !filterValue || !itemValue ) return false
                            if ( itemValue.getTime() > filterValue.getTime() ) return false
                        }
                    }
                }
            }
        }
    }
    return true
}

const addRecordsToDatabaseWhenPossible = async (row: DynamicGridRow, table: string) => {
    const result = await getRecord(table, row.rowId ?? "-")
    if ( result === undefined) {
        await addOrReplaceRecord(table, {id: row.rowId, row})                
    }
}