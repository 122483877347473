
import { onMounted, onUnmounted, ref, reactive } from "vue";
import { useStore } from "vuex";

import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
import {
  StateNavigationAction,
} from "@/business/application/statemachine/StateInterfaces";
import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
import { Commands } from "@/business/application/commands/Command";
import { StateHome } from '@/business/application/statemachine/concrete/StateHome';
import { UserProfile } from '@/infrastructure/backend/userProfile';
import { Resource } from '@/infrastructure/resource/resource';
import { openDialog } from '@/infrastructure/observables/ModalDialogNotification';
import { SyncMassData } from "@/infrastructure/serviceworker-related/SyncMassData";
import { reSyncFishbookApi } from "@/infrastructure/serviceworker-related/ReSyncFishBook";
import { isAllSynced } from "@/infrastructure/serviceworker-related/Sync";
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
import { reSyncWildBookApi } from "@/infrastructure/serviceworker-related/ReSyncWildBook";
import { DynamicNavigation } from "@/components/Shared/dynamicNavigation/DynamicNavigation";
import { Out } from "@/infrastructure/frontent/clientMessage";
import { isJagdPossible } from "@/infrastructure/serviceworker-related/LocalWildBookDataAccess"
import { ObjectSearchDataSource } from "@/components/Shared/Dynamicform/ObjectSearchDataSource";
import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { Identity } from "@/infrastructure/backend/userIdentity";
import { removeCookie } from "typescript-cookie";
import { FishingEdit } from "@/components/fishing/business/FishingEdit";
import { start } from "@popperjs/core";
import { InitialStartRequest} from "@/infrastructure/backend/async/InitialStartRequest"
import { ComboBoxDataSource } from "@/components/Shared/Dynamicform/ComboBoxDataSource"
import { getDependentComboBoxComunes } from "@/infrastructure/serviceworker-related/CascadeHuntigComboBoxes";

export default {
  name: "home-component",
  inheritAttrs: false,
  components: {},

  setup() {
    const store = useStore();
    let unsubscribe:any = {};
    const stateMachine = StateMachineServiceLocator.get();
    let currentState = stateMachine.getCurrentResultState();
    const loaderState = LoaderStateServiceForRoot.getLoaderStateService()

    

    const componentResource = reactive({
      efjTitel: "",
      efjVersion: "",
      nachrichten: "",
      fischen: "",
      jagd: "",
      jagenEnabled: false
    })

    // function setInfoText() {
    //   setTimeout( () => {
    //   const element = document.getElementById("homeInfoText")
    //   if(element)
    //     element.innerHTML = componentResource.nachrichten
    //   },3000)
    // }

    async function loadSyncResources() {
      
      componentResource.efjTitel = await Resource.getResourceTextOrDefault("eFj.App.Titel","Fischerei- und Jagdverwaltung")
      componentResource.nachrichten = await Resource.getResourceTextOrDefault("Home.HomeDaten.Nachrichten","")
      componentResource.fischen = await Resource.getResourceTextOrDefault("Home.HomeDaten.Fischen","Fischen")
      componentResource.jagd = await Resource.getResourceTextOrDefault("Home.HomeDaten.Jagd","Jagd")     
    
      
    }
  
    



    if (currentState.newMainState === "") {
      stateMachine.setAppUpdateStateDetailed( new StateHome(), {oldMainState: "Home", oldState: "Home", newMainState: "Home", newState: "Home", routeName: "Home"})
    }
    async function load() {

      

      loaderState.changeLoaderState({isInProgress: true})
      await loadSyncResources()
      await SyncMassData.load()
      try {
        await reSyncFishbookApi()
      } catch(e) {
        Out.noOperation("")
      }
      try {
        await reSyncWildBookApi()
      } catch(e) {
        Out.noOperation("")
      }

      componentResource.jagenEnabled = false
      const temp = (await  ComboBoxDataSource.GetValue("SystemParameter", "JagdFreigeschaltet")) ?? "false"
      if ( temp === "true") {

        await ObjectSearchDataSource.getData("JagdRevier","", "", "")
        const isJagd = await isJagdPossible()
        if (isJagd) componentResource.jagenEnabled = true
      }
      
      loaderState.changeLoaderState({isInProgress: false})
    }

    load()


    function backToHome() {
      stateMachine.next(
        {
          navigation: StateNavigationAction.Jump,
          conditions: [{ nameId: "*", value: "UpdateApp" }],
        },
        { lookahead: false }
      );
      changeNavigation(store);
    }

    if (currentState.newMainState === "") {
      backToHome();
    }
    currentState = stateMachine.getCurrentResultState();

    function next(newMainState: any) {
      stateMachine.next(
        {
          navigation: StateNavigationAction.Jump,
          conditions: [{ nameId: "*", value: newMainState }],
        },
        { lookahead: false }
      );
      changeNavigation(store);
    }

    async function backToLogin() {
      //const isSynced = await isAllSynced()
      //if ( isSynced) 
        next("Login");
    }

    function fishing() {
      next("Fishing");
    }

    function hunting() {
      doHuntingIfPossible()
    }

    async function doHuntingIfPossible() {
      await ObjectSearchDataSource.getData("JagdRevier","", "", "")
      const isJagd = await isJagdPossible()
      if (!isJagd) {
        const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
        notficationState.resetNotificationState()
        notficationState.changeNotificationState({
        isNotification: true, text: await Resource.getResourceText("Jagd.NotPossible.Content"), title: await Resource.getResourceText("Jagd.NotPossible.Title")})
        return
      }
      next("Hunting");
    }

    async function navigate(nav: any) {
      let isNavigation = true;
      const navigation = StateNavigationAction.Next;
      const conditions = [];
      //console.log(nav.command);
      switch (nav.command) {
        case Commands.Logout.toString(): {
          const isSynced = await isAllSynced()
          if ( !isSynced ) 
          {
            const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
            notficationState.resetNotificationState()
            notficationState.changeNotificationState({
            isNotification: true, text:  Resource.getResourceTextSyncMode("Logout.NotSynced.Content", ""), title: Resource.getResourceTextSyncMode("Logout.NotSynced.Title", "")})
            return
          }

       

         const fishingStateResult = await FishingEdit.getEditFishingStateAsync()
         if ( fishingStateResult.isStarted ) {  
          const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
          notficationState.resetNotificationState()
          
          notficationState.changeNotificationState({
            isNotification: true, text:  Resource.getResourceTextSyncMode("Logout.FishingStarted.Content", ""), title: Resource.getResourceTextSyncMode("Logout.FishingStarted.Title" , "")})
         
            return
         } 

         const currentProfile = await UserProfile.GetCurrentProfile()
         if ( !currentProfile.online ) {
          
            const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
            notficationState.resetNotificationState()

            notficationState.changeNotificationState({
            isNotification: true, text:  Resource.getResourceTextSyncMode("Offline.NotAllowed.Content", ""), title: Resource.getResourceTextSyncMode("Offline.NotAllowed.Title", "")})
            
            return 
         }


          try {
            const client = AsyncRestClient.Create(true);
            if (client.isFailure) return;

            const logoutResult = await client
                .getValue()
                .exchangeForComponents<string, string>(process.env.VUE_APP_API + "api/authentication/logout", JSON.stringify({
                userId: Identity.getIdentity().getValue().UserId,            
            })
                );  

            if (!logoutResult.isFailure) {
              const logoutContent = logoutResult.getValue()
            }
          } catch(e) {
            Out.noOperation("")
          }

         

        
          const profile: UserProfile  = new UserProfile()
          await profile.resetProfile("Home")

          try {
            removeCookie("X-UserClaim-Names", { path: '/' })
            removeCookie("X-Access-Token", { path: '/' })
            removeCookie("XSRF-TOKEN", { path: '/' })
            
          } catch(e) {
          Out.noOperation("")
          }   

          

          const startResult = await InitialStartRequest.Start( process.env.VUE_APP_API + "api/authentication/start" , "Home" )
          

          conditions.push({ nameId: "Login", value: "Login" });
          break;
        }
        case Commands.Person.toString(): {
          conditions.push({ nameId: "UserProfile", value: "UserProfile" });
          break;
        }
        case Commands.Information.toString(): {
          isNavigation = false
          const title = await Resource.getResourceText("HomeInfo.HomeInfoDaten.Titel")
          const content = await Resource.getResourceText("HomeInfo.HomeInfoDaten.Content")
          openDialog(store, {name: "Information", isOk: true, isCancel: false, titel:title, text:content, open: true, type: "info"})
          break;
        }
        case Commands.Settings.toString(): {
          conditions.push({ nameId: "Settings", value: "Settings" });
          break;
        }
      }

      if (isNavigation) {
        stateMachine.next(
          {
            navigation: navigation,
            conditions: conditions,
          },
          {
            lookahead: false,
          }
        );
        changeNavigation(store);
      }
    }

    onMounted(() => {
      if ( !DynamicNavigation.isNavigationEnabled()) {
          if (currentState.navigation) {
            try {
              DynamicNavigation.saveLastStaticNavigationState([currentState.navigation?.nav1,currentState.navigation?.nav2,currentState.navigation?.nav3,currentState.navigation?.nav4])
            } catch(e) {
              Out.noOperation("")
            }
          }

        }

        if (!DynamicNavigation.isAssigned("Home")) return       
      
      
      unsubscribe = store.subscribe((mutation) => {
        if (mutation.type === "navigationState/clickNavigationItem1") {
          navigate(currentState.navigation?.nav1);
        }
        if (mutation.type === "navigationState/clickNavigationItem2") {
          navigate(currentState.navigation?.nav2);
        }
        if (mutation.type === "navigationState/clickNavigationItem3") {
          navigate(currentState.navigation?.nav3);
        }
        if (mutation.type === "navigationState/clickNavigationItem4") {
          navigate(currentState.navigation?.nav4);
        }
      });
    });

    onUnmounted(() => {
      unsubscribe();
    });

    return {
      backToLogin,
      fishing,
      hunting,
      componentResource
    };
  },
};
