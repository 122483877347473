import { DynamicRecord } from "@/components/Shared/Dynamicform/DynamicFormData";
import { Gemeinde, WildSchutzGebiet, getRecord } from "../database/asyncDb";
import { NamedListItem } from "../generics/namedListItem";

export async function getDependentComboBoxComunes( jagdRevierRow: DynamicRecord , gemeindeRow: DynamicRecord ) : Promise<NamedListItem[]> {

    const emptyGuid = "00000000-0000-0000-0000-000000000000"
    const result: NamedListItem[] = []

    

    const records = await getRecord(Gemeinde, "Gemeinde")
    if ( ! records || ! records.objectList) {
        result.push({key: emptyGuid, value: ""})

        gemeindeRow.namedValueList = result
        gemeindeRow.namedValueListOrigin = result
        
        return result
    }


    result.push({key: emptyGuid, value: ""})
    for(const record of records.objectList) {
        if ( record.groupKey === jagdRevierRow.value.groupKey) {
            result.push({key: record.key, value: record.value})
        }
    }

    gemeindeRow.namedValueList = result
    gemeindeRow.namedValueListOrigin = result

    return result


}

export async function getDependentComboBoxWildlifePreserve( jagdRevierRow: DynamicRecord , wsRow: DynamicRecord ) : Promise<NamedListItem[]> {

    const emptyGuid = "00000000-0000-0000-0000-000000000000"
    const result: NamedListItem[] = []

    const records = await getRecord(WildSchutzGebiet, "WildSchutzGebiet")
    if ( ! records || ! records.objectList) {
        result.push({key: emptyGuid, value: ""})

        wsRow.namedValueList = result
        wsRow.namedValueListOrigin = result
    
        return result
    }


    result.push({key: emptyGuid, value: ""})
    for(const record of records.objectList) {
        if ( record.groupKey === jagdRevierRow.value.groupKey) {
            result.push({key: record.key, value: record.value})
        }
    }


    wsRow.namedValueList = result
    wsRow.namedValueListOrigin = result


    return result


}