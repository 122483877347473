import { DynamicRecord } from "@/components/Shared/Dynamicform/DynamicFormData"
import { DynamicGrid } from "@/components/Shared/dynamicList/DynamicListTypes"

export const ConvertUtcStringToDateNullable = (utcDate: string) : Date | undefined  => {
    if ( utcDate === undefined || utcDate === null || utcDate.length < 10) return undefined
    utcDate = makeValidUtcString(utcDate)
    const result = new Date(utcDate)
    printDate("ConvertUtcStringToDateNullable" , result)
    return result
}

export const ConvertUtcStringToDate = (utcDate: string) : Date  => {
    if ( utcDate === undefined || utcDate === null || utcDate.length < 10) return newDate()
    utcDate = makeValidUtcString(utcDate)

    const result = new Date(utcDate)
    printDate("ConvertUtcStringToDate" , result)
    return result
}

export const ConvertUtcStringToDateTime = (utcDate: string) : Date | undefined  => { 

    try {
        if ( !utcDate || utcDate.length < 10) return undefined

        utcDate = makeValidUtcString(utcDate)

        const [datePart, timePart] = utcDate.split('T');
        const [year, month, day] = datePart.split('-');    
        const timePartWithoutZ = timePart.slice(0, -1); 
        const [hour, minute, second, _] = timePartWithoutZ.split(':');
    
        const result = new Date(Number.parseInt(year, 10), Number.parseInt(month, 10) - 1, Number.parseInt(day, 10), Number.parseInt(hour, 10), Number.parseInt(minute, 10), Number.parseInt(second, 10));
        return result;
    
    } catch(e) {
        try {
            return GenerateCorrectTime( ConvertUtcStringToDate(utcDate))
        } catch(e) {
            return undefined
        }
    }


}

export const ConvertUtcStringToDateOrUndefined = (utcDate: string) : Date | undefined  => {
    if ( utcDate === undefined || utcDate === null || utcDate.length < 10) return undefined
    utcDate = makeValidUtcString(utcDate)
    // if ( isUTCDay(utcDate)) {
    //     const utcResult = new Date(utcDate)
    //     utcResult.setDate(utcResult.getDate() - 1)
    //     return utcResult
    // }
    const result = new Date(utcDate)
    printDate("ConvertUtcStringToDateOrUndefined" , result)
    return result
}

const isUTCDay = (utcDate: string) : boolean => {
    try {
        const regex = /00:00:00\.000Z$/;
        return regex.test(utcDate);    
    } catch(e) {
        return false
    }
}



export const makeValidUtcString = (utc: string) : string => {
    if ( !utc) return utc
    utc = utc.trim().replace(" ", "T")
    if ( utc.length > 10 ) {
        const temp: string[] = utc.split(":")
        if ( temp.length === 4) {
            utc = temp[0] + ":" + temp[1] + ":" + temp[2] + "." + temp[3]
        }
    }

    return utc
}
export const ConvertEuropeanToDate = (euDate: string ) : Date | undefined => {
    
    if ( euDate === null || euDate === undefined ) return undefined
    if ( euDate.length < 10) return undefined
    const utcDate: string[] = euDate.split(".")
    if ( utcDate.length !== 3) return undefined
    let date : Date | undefined = undefined
    try {
        date = new Date(`${utcDate[2]}-${utcDate[1]}-${utcDate[0]}`)
    } catch(e) {
        return undefined
    } 
    printDate("ConvertEuropeanToDate" , date)
    return date
}

export const ConvertCanonicalToDate = (euDate: string ) : Date | undefined => {
    
    if ( euDate === null || euDate === undefined ) return undefined
    if ( euDate.length < 10) return undefined
    const utcDate: string[] = euDate.split("-")
    if ( utcDate.length !== 3) return undefined
    let date : Date | undefined = undefined
    try {
        date = new Date(`${utcDate[0]}-${utcDate[1]}-${utcDate[2]}`)
    } catch(e) {
        return undefined
    } 
    printDate("ConvertCanonicalToDate" , date)
    return date
}

export const ConvertCanonicalToDayDate = (euDate: string , offset: boolean ) : Date | undefined => {
    
    if ( euDate === null || euDate === undefined ) return undefined
    if ( euDate.length < 10) return undefined
    const utcDate: string[] = euDate.split("-")
    if ( utcDate.length !== 3) return undefined
    let date : Date | undefined = undefined
    try {
        let day = utcDate[2].split("T")
        if ( day.length < 2) {
            day = utcDate[2].split(" ")
            if ( day.length < 2) 
                return undefined
    
        }

       
        if ( offset ) {
            date = new Date(`${utcDate[0]}-${utcDate[1]}-${utcDate[2]}`)
            printDate("ConvertCanonicalToDayDate_offset" , date)
            return date;
        }

        date = new Date(`${utcDate[0]}-${utcDate[1]}-${day[0]}T00:00:00.00Z`)
        if ( ! date) {
            date = new Date(`${utcDate[0]}-${utcDate[1]}-${day[0]} 00:00:00.00Z`)
        }
    } catch(e) {
        return undefined
    } 
    printDate("ConvertCanonicalToDayDate" , date)
    return date
}


export const ConvertDateToIsoString = (date: Date) : string => {
    const result = toLocalISOString(date)
    return result 
}

export const ConvertDateToDayString = (date: Date | undefined) : string => {
    if ( date === undefined) return ""

    return `${date.getDate().toString().padStart(2,"0")}.${(date.getMonth()+1).toString().padStart(2,"0")}.${date.getFullYear().toString().padStart(4,"0")}`

}

export const isToday = (dateTime: Date) => {
    const today = newDate()
    return dateTime.getDate() == today.getDate() &&
    dateTime.getMonth() == today.getMonth() &&
    dateTime.getFullYear() == today.getFullYear()
  }

  export function getCurrentDayDate() {
    const dateTime: Date = newDate()
    const result = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())
    printDate("getCurrentDayDate" , result)
    return result
  }

    export function isSameDay(date1?: Date, date2?: Date) { 
        if ( ! date1 || ! date2 || ! isDate(date1) || ! isDate(date2)) return false
        return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
    }


  export function getDateFromGermanStringDate(stringDate: string | undefined) : Date | undefined {
    if (! stringDate) return undefined
    if ( stringDate.length < 10) return undefined

    const temp = stringDate.split(".")
    if (temp.length < 3) return undefined

    try {
        const result = new Date(parseInt(temp[2]), parseInt(temp[1])-1, parseInt(temp[0]))
        printDate("getDateFromGermanStringDate" , result)
        return result
    } catch (e) {
     return undefined
    }

  }

    export function addMonths(date: Date, months: number) {
        date.setMonth(date.getMonth() + +months);
        return date;
    }

    export function addWeeks(date: Date, days: number) {
        date.setDate(date.getDate() + days * 7);
        return date;
    }

    export function addDays(date: Date, days: number) {
        date.setDate(date.getDate() + days);
        return date;
    }

  export const getDateWithoutTime = (dateTime: Date) : Date => {
    try {
        const result = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())
        printDate("getDateWithoutTime" , result)
        return result       
    } catch(e) {
        console.error("getDateWithoutTime: " + e)
    }
    return newDate()
  }  

 export function GetEndOfDayIsoString() : string  {
    const end = GetCurrentEndOfDayDateTime();
    return toLocalISOString(end)
}

export function GenerateCorrectTime(date: Date) {    
    const result = date
    printDate("GenerateCorrectTime" , result)
    return result
}

export function GetCurrentEndOfDayDateTime() : Date  {
    const dateResult: Date = newDate()
    dateResult.setHours(23,59,59,999);
    printDate("GetCurrentEndOfDayDateTime" , dateResult)
    return dateResult
}

export function GetEndOfDayDateTime(date: Date) : Date  {
    const dateResult: Date = new Date(date)  
    dateResult.setHours(23,59,59,999);
   
    printDate("GetEndOfDayDateTime" , dateResult)
    return dateResult
}

export function GetDateDiffMinutes(stop: Date | undefined , start: Date | undefined) : number {
    if (!stop || ! start) return 0

    const diff = (stop.getTime() - start.getTime()) / 1000;
    const raWDiff = Math.abs(Math.round(diff / 60));  
    return raWDiff 
}

function toLocalISOString(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    // Format the local date and time as needed (e.g., YYYY-MM-DDTHH:MM:SS.mmm)
    const localISOString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(3, '0')}`;

    return localISOString;
}

export function GenerateAppSynchronId() {
    const date = newDate()   
    const datePart = `${date.getFullYear().toString().padStart(4,"0")}-${(date.getMonth()+1).toString().padStart(2,"0")}-${date.getDate().toString().padStart(2,"0")}T`
    const timepart = `${date.getHours().toString().padStart(2,"0")}:${date.getMinutes().toString().padStart(2,"0")}:${date.getSeconds().toString().padStart(2,"0")}`
    const milliseconds = `${date.getMilliseconds().toString().padStart(3,"0")}`

    return datePart + " " + timepart + "." + milliseconds
}

export function isGreaterAppSynchronId(syncId1: string | undefined, syncId2: string | undefined) : boolean {
    if (!syncId1 || syncId1.length  < 1 ) return false
    if (!syncId2 || syncId2.length  < 1 ) return false
    if (syncId2 > syncId1) return true

    return false
}

export function isDifferentAppSynchronId(syncId1: string | undefined, syncId2: string | undefined) : boolean {
    if (!syncId1 || syncId1.length  < 1 ) return true
    if (!syncId2 || syncId2.length  < 1 ) return true
    if (syncId2 !== syncId1) return true

    return false
}

export function printDate(functionName: string , currentDate: Date) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    console.warn(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)

}

export function newDate() {
    const result = new Date()    
    printDate("newDate" , result)
    return result
}

export function overwriteDateValue( dateValue: Date) : Date {
    return dateValue;
}


export function overWriteValueFromDateValue(dateValue: Date) : string {
    return dateValue.toString();
}

export function overwriteSerializedLocalDate(originRecords?: DynamicRecord[], parsedRecords?: DynamicRecord[]) {   
    if ( originRecords && originRecords.length > 0 && parsedRecords && parsedRecords.length > 0) { 
      for ( const row of originRecords) {
        for (const tempRow of parsedRecords) {
          if ( row.dateValue && isDate(row.dateValue) && tempRow.formularFeldname === row.formularFeldname) {
              tempRow.dateValue = row.dateValue
          }
        }
      }
    }
}

export function overwriteSerializedSingleLocalDate(originRecord: DynamicRecord, parsedRecord: DynamicRecord) {   
    if ( originRecord.dateValue && isDate(originRecord.dateValue)) {
        parsedRecord.dateValue = originRecord.dateValue
    }
}


export function overwriteSerializedLocalGridDate(originGrid: DynamicGrid, parsedGrid: DynamicGrid) {    
    if ( originGrid.rows && parsedGrid.rows) {
        for ( const item of originGrid.rows!) {
            for ( const targetItem of parsedGrid.rows!) {
                if ( item.id === targetItem.id) {
                    if ( item.detailForm && targetItem.detailForm && item.detailForm.view && targetItem.detailForm.view) {
                        for ( const field of item.detailForm?.view) {
                            for( const targetField of targetItem.detailForm?.view) {
                                if ( field.formularFeldname === targetField.formularFeldname) {
                                    overwriteSerializedSingleLocalDate(field, targetField)
                                }
                        }
                    }
                }
                    
                }
            }
        }
    }

}

function isDate(value: any): boolean {
    return value instanceof Date && !isNaN(value.getTime());
}
