
import { onMounted, onUnmounted, reactive, } from "vue";
import { useStore } from "vuex";

import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
import {
  StateNavigationAction,
} from "@/business/application/statemachine/StateInterfaces";
import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
import { Commands } from "@/business/application/commands/Command";
import { openDialog } from "@/infrastructure/observables/ModalDialogNotification"
import { StateHunting } from '@/business/application/statemachine/concrete/StateHunting';
import GeoMap from "@/components/Shared/geo/GeoMap.vue"
import { GeoState, GeoStateAction, GeoView } from "@/components/Shared/geo/hooks/ChangeGeoMap";
import { indentIcon } from "@progress/kendo-svg-icons";
import { DynamicNavigation } from "@/components/Shared/dynamicNavigation/DynamicNavigation";
import { Out } from "@/infrastructure/frontent/clientMessage";
import { GeoStoreData } from "@/components/Shared/geo/hooks/GeoStoreData";
import { Resource } from "@/infrastructure/resource/resource";
import { OfflineForms } from '@/components/Shared/Dynamicform/DynamicFormPreload';
import { DynamicRecord } from "@/components/Shared/Dynamicform/DynamicFormData";

export default {
  name: "hunting-component",
  inheritAttrs: false,
  components: {GeoMap},

  setup() {
    const store = useStore();
    DynamicNavigation.reset()
    let unsubscribe:any = {};

    GeoStoreData.enableEditMode()

    const stateMachine = StateMachineServiceLocator.get();
    let currentState = stateMachine.getCurrentResultState();
    if (currentState.newMainState === "") {
      stateMachine.setAppUpdateStateDetailed( new StateHunting(), {oldMainState: "*", oldState: "*", newMainState: "Hunting", newState: "Hunting", routeName: "Hunting"})
    }

    const geoViewActionService = GeoView.getService()

    const geoRequest = reactive({
      editMode: "ReadOnly",
      gpsMode: "OnStartup",
      checkInsidePolygon: false,
      displayClass: "big",
      mapDivId: "map",
      //centerVisible: false
    })

    const features = reactive({
      jagd: false,
      fallwild: false,
      beobachtung: false,
      beobachtungSpezial: false
    })

    const componentResource = reactive({
      jagd: ""
    })

    async function loadSyncResources() {
      componentResource.jagd = await Resource.getResourceTextOrDefault("HomeJagd.HomeJagdDaten.Jagd","Jagen")
    }

    async function load() {   
      await loadSyncResources()  
      await manageFeatures() 
      const state: GeoState = {
        action: GeoStateAction.SetPositionOnly,
        wgs84X: undefined,
        wgs84Y: undefined,
        revierNumber: undefined,
        typ: undefined,
        lastState: false,
        isEditable: undefined
        
      }
      geoViewActionService.changeGeoState(state)
     
    }

    async function manageFeatures() {
      const homeJagdForm = await OfflineForms.getForm("HomeJagd")
      if ( homeJagdForm) {
        homeJagdForm.view.forEach((element: DynamicRecord) => {
          if (element.formularFeldname === "ButtonJagen") {
            features.jagd = element.istSichtbar
          }
          if (element.formularFeldname === "ButtonFallwild") {
            features.fallwild = element.istSichtbar
          }
          if (element.formularFeldname === "ButtonBeobachtung") {
            features.beobachtung = element.istSichtbar
          }
          if (element.formularFeldname === "ButtonBeobachtungSpezial") {
            features.beobachtungSpezial = element.istSichtbar
          }

          if ( !features.jagd && !features.fallwild && !features.beobachtung && !features.beobachtungSpezial) {
            features.jagd = true
            features.fallwild = true
            features.beobachtung = true
            features.beobachtungSpezial = true
          }

        });
      }


    }

    load()
    

    function backToHome() {
      stateMachine.next(
        {
          navigation: StateNavigationAction.Jump,
          conditions: [{ nameId: "*", value: "UpdateApp" }],
        },
        { lookahead: false }
      );
      changeNavigation(store);
    }

    if (currentState.newMainState === "") {
      backToHome();
    }
    currentState = stateMachine.getCurrentResultState();

    function next(newMainState: any) {
      stateMachine.next(
        {
          navigation: StateNavigationAction.Jump,
          conditions: [{ nameId: "*", value: newMainState }],
        },
        { lookahead: false }
      );
      changeNavigation(store);
    }

    function wildbuchEintragJagd () {
      next("WildBookEntry");
    }

    function wildbuchFallWild () {
      next("WildBookFall");
    }

    function wildbuchBeobachtung () {
      next("WildBookObserve");
    }

    function wildbuchBeobachtungSpezial () {
      next("WildBookObserveSpec");
    }


    async function navigate(nav: any) {
      const isNavigation = true;
      const navigation = StateNavigationAction.Next;
      const conditions = [];
      let isNext = true;
      
      switch (nav.command) {
        case Commands.Products.toString(): {
          conditions.push({ nameId: "Products", value: "Products" });

          break;
        }
        case Commands.Information.toString(): {
          const title = await Resource.getResourceText("Jagd.Information.Titel")
          const content = await Resource.getResourceText("Jagd.Information.Content")
          openDialog(store, {name: "JagdInfo", isOk: true, isCancel: false, titel: title, text: content, open: true, type: "info"})
          return;
        }
        case Commands.Wildbuch.toString(): {
          conditions.push({ nameId: "Wildbuch", value: "WildBook" });
          break;
        }

        
        case Commands.PreviousPage.toString(): {
          isNext = false;
          conditions.push({ nameId: "Home", value: "Home" });
          break;
        }
      }

      if (isNavigation && isNext) {
        stateMachine.next(
          {
            navigation: navigation,
            conditions: conditions,
          },
          {
            lookahead: false,
          }
        );
        changeNavigation(store);
      }

      if (isNavigation && !isNext) {
        stateMachine.previous(
          {
            navigation: navigation,
            conditions: conditions,
          },
          {
            lookahead: false,
          }
        );
        changeNavigation(store);
      }

    }

    onMounted(() => {      
      unsubscribe = store.subscribe((mutation) => {
        if ( !DynamicNavigation.isNavigationEnabled()) {
          if (currentState.navigation) {
            try {
              DynamicNavigation.saveLastStaticNavigationState([currentState.navigation?.nav1,currentState.navigation?.nav2,currentState.navigation?.nav3,currentState.navigation?.nav4])
            } catch(e) {
              Out.noOperation("")
            }
          }
        }

        if (!DynamicNavigation.isAssigned("Hunting")) return       

        if (mutation.type === "navigationState/clickNavigationItem1") {
          navigate(currentState.navigation?.nav1);
        }
        if (mutation.type === "navigationState/clickNavigationItem2") {
          navigate(currentState.navigation?.nav2);
        }
        if (mutation.type === "navigationState/clickNavigationItem3") {
          navigate(currentState.navigation?.nav3);
        }
        if (mutation.type === "navigationState/clickNavigationItem4") {
          navigate(currentState.navigation?.nav4);
        }
      });

      
    });

    onUnmounted(() => {
      geoViewActionService.reset()      
      unsubscribe();
    });

    return {
      wildbuchEintragJagd,
      wildbuchFallWild,
      wildbuchBeobachtungSpezial,
      wildbuchBeobachtung,
      geoRequest,
      componentResource,
      features
    };
  },
};
