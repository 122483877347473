import { Dictionary } from '@/infrastructure/generics/dictionary';
import { DynamicRecord } from '@/components/Shared/Dynamicform/DynamicFormData';
import { UserProfile } from '@/infrastructure/backend/userProfile';
import { Identity } from '@/infrastructure/backend/userIdentity';
import {v4 as uuidv4} from 'uuid';
import { ConvertDateToDayString, ConvertUtcStringToDate, overwriteSerializedLocalDate } from '@/infrastructure/functional/datetimehelper';
import { Out } from '@/infrastructure/frontent/clientMessage';
import { listUnorderedSquareIcon } from '@progress/kendo-svg-icons';
import { WarenKorb, WebShopState, addOrReplaceRecord, deleteRecord, getRecord } from '@/infrastructure/database/asyncDb';
import { SaveProductResult } from './RemoteWarenKorb';

export interface LocalWarenkorbElement {
    id: string,
    rows: DynamicRecord[]
}

export type ShoppingCardLocal = {
    artificalId: string
    id: string
    language: string
    autoConfirmationEnabled: boolean
    currency: string
    customer: ShoppingCardCustomerLocal
    shoppingCardLineItems: ShoppingCardItemLocal[] | undefined
    postFinanceScriptReference: string
    transactionId: string
    transactionReference: string
    amountIncludeTax: string
    providerStatus: string
    transferDatum: string
    brand: string
    errorResourceId: string
}

export type ShoppingCardCustomerLocal = {
    userId: string
}

export type ShoppingCardItemLocal = {
    id: string
    sku: string
    quantity: string
    amountIncludeTax: string
    productName: string
    timePeriod: string
    form: DynamicRecord[]
    icon: string
    idJagdRevier?: string
    idFischereiRevier? : string
}



export class LocalWarenkorb {
    private static forms: LocalWarenkorbElement[]= []
    private static shoppingCard: ShoppingCardLocal | undefined = undefined

   

    public static async GetShoppingCard() :  Promise<ShoppingCardLocal | undefined> {
        const temp = await LocalWarenkorb.LoadWebShopFromLocalDb()
        if (temp) LocalWarenkorb.shoppingCard = temp

        if (! LocalWarenkorb.shoppingCard || !LocalWarenkorb.shoppingCard.id || LocalWarenkorb.shoppingCard.id.length < 1 ) {
            LocalWarenkorb.shoppingCard = await LocalWarenkorb.CreateShoppingCard()
        } else {

            LocalWarenkorb.shoppingCard.shoppingCardLineItems = []
            await LocalWarenkorb.LoadWarenKorbFromLocalDb()
            
            if (LocalWarenkorb.forms && LocalWarenkorb.forms.length > 0) { 

                LocalWarenkorb.shoppingCard.language = (await UserProfile.GetClientSettingsAsync()).language
                for (const item  of LocalWarenkorb.forms ) {
                    const lineItem: ShoppingCardItemLocal = {
                        id: '',
                        sku: '',
                        quantity: '',
                        amountIncludeTax: '',
                        productName: '',
                        timePeriod: '',
                        form: item.rows,
                        icon: ""
                    }
                    for(const row of item.rows ) {
        
                        if( row.feldTyp === "ValueCheck" ) {
                            Out.noOperation("")
                            if (! row.value) {
                                row.value = "false"
                                continue
                            }
                            if ( typeof(row.value) === "boolean" ) {
                                if (!row.value) {
                                    row.value = "false"
                                } else { 
                                    row.value = "true"
                                }
                            }
                            if ( typeof(row.value) === "string" && row.value.length < 1 ) {
                                row.value = "false"
                            }
                        }
        
                        if (row.formularFeldname === "ProduktName" ) {
                            lineItem.productName = row.value
                        }
                        if (row.formularFeldname && row.formularFeldname.toLowerCase() === "id" ) {
                            lineItem.id = row.value
                        }
                        if (row.formularFeldname === "ArtikelPreis" ) {
                            lineItem.amountIncludeTax = row.value
                        }
                        if (row.formularFeldname === "ProduktId" ) {
                            lineItem.sku = row.value
                        }
                        if (row.formularFeldname === "ProduktIcon" ) {
                            lineItem.icon = row.value
                        }
                        if (row.formularFeldname === "ProduktAnzahl" ) {
                        lineItem.quantity = row.value 
                        }
                        if (row.formularFeldname === "GueltigAb" ) {
                            try {
                                lineItem.timePeriod = ConvertDateToDayString( ConvertUtcStringToDate( row.value ) )
                            } catch(e) {
                                Out.noOperation("")
                            }
                            
                        }
                    }
                    LocalWarenkorb.shoppingCard.shoppingCardLineItems.push(lineItem)
                }
            }
           
            await LocalWarenkorb.SaveWebShopToLocalDb()
        }
        return LocalWarenkorb.shoppingCard
    }
  
    public static async  CreateShoppingCard() : Promise<ShoppingCardLocal> {

        const shoppingcard: ShoppingCardLocal = {
            artificalId: uuidv4(),
            id: uuidv4(),
            language: (await UserProfile.GetClientSettingsAsync()).language,
            autoConfirmationEnabled: false,
            currency: 'CHF',
            customer: {
                userId: Identity.getIdentity().getValue().UserId
            },
            shoppingCardLineItems: undefined,
            postFinanceScriptReference: "",
            transactionId: "",
            amountIncludeTax: "",
            transactionReference: "",
            brand: "",
            providerStatus: "",
            transferDatum: "",
            errorResourceId: ""
        }

        await LocalWarenkorb.LoadWarenKorbFromLocalDb()

        if (! LocalWarenkorb.forms || LocalWarenkorb.forms.length === 0) return shoppingcard

        shoppingcard.shoppingCardLineItems = []

        for (const item  of LocalWarenkorb.forms ) {
            const lineItem: ShoppingCardItemLocal = {
                id: '',
                sku: '',
                quantity: '',
                amountIncludeTax: '',
                productName: '',
                timePeriod: '',
                form: item.rows,
                icon: ""
            }
            for(const row of item.rows ) {

                if( row.feldTyp === "ValueCheck" ) {
                    Out.noOperation("")
                    if (! row.value) {
                        row.value = "false"
                        continue
                    }
                    if ( typeof(row.value) === "boolean" ) {
                        if (!row.value) {
                            row.value = "false"
                        } else { 
                            row.value = "true"
                        }
                    }
                    if ( typeof(row.value) === "string" && row.value.length < 1 ) {
                        row.value = "false"
                    }
                }

                if (row.formularFeldname === "ProduktName" ) {
                    lineItem.productName = row.value
                }
                if (row.formularFeldname && row.formularFeldname.toLowerCase() === "id" ) {
                    lineItem.id = row.value
                }
                if (row.formularFeldname === "ArtikelPreis" ) {
                    lineItem.amountIncludeTax = row.value
                }
                if (row.formularFeldname === "ProduktId" ) {
                    lineItem.sku = row.value
                }
                if (row.formularFeldname === "ProduktIcon" ) {
                    lineItem.icon = row.value
                }
                if (row.formularFeldname === "ProduktAnzahl" ) {
                   lineItem.quantity = row.value 
                }
                if (row.formularFeldname === "GueltigAb" ) {
                    try {
                        lineItem.timePeriod = ConvertDateToDayString( ConvertUtcStringToDate( row.value ) )
                    } catch(e) {
                        Out.noOperation("")
                    }
                    
                }
            }
            shoppingcard.shoppingCardLineItems.push(lineItem)
        }
        
        LocalWarenkorb.shoppingCard = shoppingcard
        await LocalWarenkorb.SaveWebShopToLocalDb()
        return shoppingcard
    }

    public static async RefreshShoppingCardItems( remoteResult: SaveProductResult) {
        
        if ( !remoteResult || !remoteResult.produktRueckMeldungen || remoteResult.produktRueckMeldungen.length == 0 ) return

        await LocalWarenkorb.LoadWarenKorbFromLocalDb()
        let i = 0
        try {
            for(const form of LocalWarenkorb.forms) {
                for(const row of form.rows) {
                    if (row.formularFeldname === "ArtikelPreis" ) {
                        row.value = remoteResult.produktRueckMeldungen[i].produkt.verkaufsPreis
                    }
                }
                i++
            }
        } catch(e) {
            Out.noOperation("")
        }
        await LocalWarenkorb.SaveWarenKorbToLocalDb()

        // SKUs
        if (! LocalWarenkorb.shoppingCard || !LocalWarenkorb.shoppingCard.shoppingCardLineItems)
            return

        await LocalWarenkorb.LoadWebShopFromLocalDb()
        i=0
        try {
            for(const row of LocalWarenkorb.shoppingCard.shoppingCardLineItems) {
                row.amountIncludeTax = remoteResult.produktRueckMeldungen[i].produkt.verkaufsPreis
                i++
            }
        } catch(e) {
            Out.noOperation("")
        }
        
        await LocalWarenkorb.SaveWebShopToLocalDb()

        LocalWarenkorb.shoppingCard = await LocalWarenkorb.GetShoppingCard()
    }

    public static async AddProductItem( id: string , rows: DynamicRecord[]) : Promise<void> {
        await LocalWarenkorb.LoadWarenKorbFromLocalDb()
        LocalWarenkorb.forms.push( {id: id, rows: rows})
        for( const row of rows ) {
            if ( row.formularFeldname && row.formularFeldname.toLowerCase() === "id") {
                row.value = id
            }
        }
        await LocalWarenkorb.SaveWarenKorbToLocalDb()
        LocalWarenkorb.shoppingCard = await LocalWarenkorb.GetShoppingCard()
    }

    public static async DeleteProductItem( id: string ) : Promise<void> {
       
        await LocalWarenkorb.LoadWarenKorbFromLocalDb()
        const temp: LocalWarenkorbElement[] = []

        if (! LocalWarenkorb.forms || LocalWarenkorb.forms.length === 0) return
        for(const row of LocalWarenkorb.forms) {
            if(row.id === id) {
                continue
            }
            if ( row.rows  ) {
                for( const formRow of row.rows ) {
                    if ( formRow.formularFeldname && formRow.formularFeldname.toLowerCase() === "id") {
                        if( formRow.value === id )
                            continue
                    }
                }
            }
            temp.push(row)
        }
        LocalWarenkorb.forms = temp
        await LocalWarenkorb.SaveWarenKorbToLocalDb()
        LocalWarenkorb.shoppingCard = await LocalWarenkorb.GetShoppingCard()
      
    }

    public static async Clear( ) : Promise<void> {
        await LocalWarenkorb.DeleteLocalDbWarenKorb()
        await LocalWarenkorb.DeleteLocalDbWebShop()
        LocalWarenkorb.forms = []
        LocalWarenkorb.shoppingCard = undefined
    }

    public static async SaveShoppingCard(card : ShoppingCardLocal) : Promise<void> {
        if (! card || !card.id || card.id.length < 1) return
        LocalWarenkorb.shoppingCard = card
        await this.SaveWebShopToLocalDb()
    }

    public static async GetProductItem(id: string) : Promise<DynamicRecord[] | undefined> {
        await LocalWarenkorb.LoadWarenKorbFromLocalDb()
        if (! LocalWarenkorb.forms || LocalWarenkorb.forms.length === 0) return undefined
        try {
            for(const row of LocalWarenkorb.forms) {
                if(row.id === id) return row.rows
            }
        } catch(e) {
            return undefined
        }
    }


    private static async LoadWarenKorbFromLocalDb() : Promise<LocalWarenkorbElement[] | undefined> {
        LocalWarenkorb.forms = []
        const data =  await getRecord(WarenKorb, "warenKorb")
        if ( data) {
            if ( data.products) {
                if (data.products.length > 0 ) {
                    LocalWarenkorb.forms = data.products as LocalWarenkorbElement[]
                    return LocalWarenkorb.forms
                }
            }
        }
        return undefined
    }

    private static async SaveWarenKorbToLocalDb() {
        await LocalWarenkorb.DeleteLocalDbWarenKorb()
        if (LocalWarenkorb.forms && LocalWarenkorb.forms.length > 0) {


            const forms = JSON.parse(JSON.stringify( LocalWarenkorb.forms))
            
            for(const item of LocalWarenkorb.forms) {
                for( const row of forms)
                if( row.id === item.id) {
                    overwriteSerializedLocalDate(item.rows, row)
                }
            }


            await addOrReplaceRecord(WarenKorb, {id:"warenKorb", "products": forms })
        }
    }

    private static async DeleteLocalDbWarenKorb() {
        try {
            await deleteRecord(WarenKorb, "warenKorb")
        } catch(e) {
            Out.error("DeleteLocalDbWarenKorb")
        }
    }


    private static async LoadWebShopFromLocalDb() : Promise<ShoppingCardLocal | undefined> {
        LocalWarenkorb.shoppingCard = undefined
        const data =  await getRecord(WebShopState, "webShop")
        if ( data) {
            if ( data.shoppingCard) {
                if (data.shoppingCard.id && data.shoppingCard.id.length > 0 ) {
                    LocalWarenkorb.shoppingCard = data.shoppingCard as ShoppingCardLocal
                    return LocalWarenkorb.shoppingCard
                }
            }
        }
        return undefined
    }

    private static async SaveWebShopToLocalDb() {
        await LocalWarenkorb.DeleteLocalDbWebShop()
        if (LocalWarenkorb.shoppingCard && LocalWarenkorb.shoppingCard?.id && LocalWarenkorb.shoppingCard.id.length > 0) {

            const result = JSON.parse(JSON.stringify( LocalWarenkorb.shoppingCard))

            for( const item of LocalWarenkorb.shoppingCard.shoppingCardLineItems!) {
                for (const row of result.shoppingCardLineItems) {
                    if (row.id === item.id) {
                        overwriteSerializedLocalDate(item.form, row.form)
                    }
                }
               
            }
            


            await addOrReplaceRecord(WebShopState, {id:"webShop", "shoppingCard": result })
        }
    }

    private static async DeleteLocalDbWebShop() {
        try {
            await deleteRecord(WebShopState, "webShop")
        } catch(e) {
            Out.error("DeleteLocalDbWebShop")
        }
    }
}